export enum WebVitalsName {
    CLS = 'CLS',
    FID = 'FID',
    LCP = 'LCP',
    FCP = 'FCP',
    TTFB = 'TTFB',
}

export enum ClickStreamEventInnerType {
    WebVitals = 'web-vitals',
    Analytics = 'analytics',
}

export enum TrackingElasticEvent {
    PerformAction = 'perform-action',
    WindowMessage = 'window-message',
    Mb2CbPerformAction = 'mb-2-cb-perform-action',
    Mb2CbMinimizeApp = 'mb-2-cb-minimize-app',
    Mb2CbPassCloseIntent = 'mb-2-cb-pass-close-intent',
    Mb2CbPassAppClosed = 'mb-2-cb-pass-app-closed',
}
