import Container, { Service } from 'typedi';
import { ILogger, LoggerProvider } from '../logger';
import { ISiteSegmentationContext } from './models/ISiteSegmentationContext';
import { LocalSimpleStoreService } from '../storage/implementations/simple-store';
import { StorageItemEnum } from '../../models/enums/storage-enums';
import PageContextManager from 'page-context-manager';
import { UserContextToken, UserDataStoreDeferredObjectToken } from '../../injection-tokens';
import { IUserContext } from '../user-context/user-context-interface';

@Service()
export class SiteSegmentationContext {
    private readonly _logger: ILogger;
    private readonly _localSimpleStorageService: LocalSimpleStoreService;
    private readonly _userContext: IUserContext;

    constructor() {
        this._logger = Container.get(LoggerProvider).getLogger('SiteSegmentationContext');
        this._localSimpleStorageService = Container.get(LocalSimpleStoreService);
        this._userContext = Container.get(UserContextToken);
        const userDataStoreDeferredObjectToken = Container.get(UserDataStoreDeferredObjectToken);

        userDataStoreDeferredObjectToken.promise.then(() => {
            if (!this._userContext.IsAuthenticated) {
                this.setSiteSegmentation();
            }
        });
    }

    private _generate = (): ISiteSegmentationContext => {
        const localizationData = PageContextManager.getLocalizationData();
        const userData = PageContextManager.getUserData();
        const deviceData = PageContextManager.getDeviceData();
        const infraData = PageContextManager.getInfraData();
        const envData = PageContextManager.getEnvironmentData();

        const siteSegmentationContext: ISiteSegmentationContext = {
            BankrollCurrency: localizationData.currency,
            IsAuthenticated: !!userData.cid,
            IsHybrid: deviceData.isHybrid,
            SystemId: Number(envData.systemId),
            Serial: Number(infraData.serial),
        };
        if (userData.cid) {
            siteSegmentationContext.IsFTD = userData.isFtd;
            siteSegmentationContext.PlayerStatus = userData.statusGroupId;
        }

        return siteSegmentationContext;
    };

    public setSiteSegmentation = () => {
        const siteSegmentationContext = this._generate();

        this._localSimpleStorageService.set(
            StorageItemEnum.SiteSegmentationContext,
            this._encode(siteSegmentationContext),
        );
    };

    public update = (siteSegmentationContext: Partial<ISiteSegmentationContext>) => {
        let existingSiteSegmentation = this.getJSON();
        if (!existingSiteSegmentation) {
            existingSiteSegmentation = this._generate();
        }

        const updatedSiteSegmentationContext: ISiteSegmentationContext = {
            ...existingSiteSegmentation,
            ...siteSegmentationContext,
        };

        this._localSimpleStorageService.set(
            StorageItemEnum.SiteSegmentationContext,
            this._encode(updatedSiteSegmentationContext),
        );
    };

    public getString = () => {
        return this._localSimpleStorageService.get(StorageItemEnum.SiteSegmentationContext);
    };

    public getJSON = (): ISiteSegmentationContext => {
        const siteSegmentationContextString = this.getString();
        if (!siteSegmentationContextString) return null;

        return this._decode(siteSegmentationContextString);
    };

    private _encode = (siteSegmentationContext: ISiteSegmentationContext) =>
        encodeURIComponent(JSON.stringify(siteSegmentationContext));
    private _decode = (siteSegmentationContextString: string) =>
        JSON.parse(decodeURIComponent(siteSegmentationContextString));
}
