import Container, { Service } from 'typedi';
import { WindowToken } from '../../../injection-tokens';
import { IMiniCashier } from '../../mini-cashier/models';
import { IPostMessageService } from '../../post-message';
import { PreloaderManager } from '../../ui/preloader/preloader-manager';
import { ISessionManager } from '../../session-manager/interfaces/ISessionManager';
import { LoaderManager } from '../../../loaders/LoaderManager';
import { UIService } from '../../ui';
import { UserInteraction } from '../../user-interaction';
import { ClientTracking } from '../../tracking/models/clientTracking';
@Service()
export class WindowEventsSubscriber {
    private readonly _window: Window;
    private readonly _preLoaderManager: PreloaderManager;
    private readonly _uiService: UIService;
    private readonly _userInteraction: UserInteraction;
    private readonly _clientTracking: ClientTracking;

    protected get _miniCashierPromise(): Promise<IMiniCashier> {
        return LoaderManager.Instance.MiniCashierLoader.Instance;
    }

    private get _sessionManagerPromise(): Promise<ISessionManager> {
        return LoaderManager.Instance.SessionManagerLoader.Instance;
    }

    private get _postMessageServicePromise(): Promise<IPostMessageService> {
        return LoaderManager.Instance.PostMessageServiceLoader.Instance;
    }

    constructor() {
        this._window = Container.get(WindowToken);
        this._preLoaderManager = Container.get(PreloaderManager);
        this._uiService = Container.get(UIService);
        this._userInteraction = Container.get(UserInteraction);
        this._clientTracking = Container.get(ClientTracking);
        this._window.addEventListener('load', this.onWindowLoad);
        this._window.addEventListener('message', this.onWindowMessage, false);
        this._window.addEventListener(
            'preloader-finished-event',
            this.onWindowPreLoaderFinishedEvent,
        );
        this._window.addEventListener('click', this.onWindowClick.bind(this), true);
        this._window.addEventListener('scroll', this.onWindowScroll);
        this._window.addEventListener('resize', this.onWindowResize);

        const hamburgerButton = this._window.document.querySelector('.aChamburger');
        if (hamburgerButton !== null) {
            hamburgerButton.addEventListener('click', this.onBurgerMenuClicked);
        }

        const exitOffCanvas = this._window.document.querySelector('.exit-off-canvas');
        if (exitOffCanvas !== null) {
            exitOffCanvas.addEventListener('click', this.onExitOffCanvasClicked);
        }
    }

    private onWindowLoad = async () => {
        const miniCashier = await this._miniCashierPromise;
        miniCashier?.getMiniCashierDetails();
    };

    private onWindowMessage = async (event) => {
        const postMessageService = await this._postMessageServicePromise;
        postMessageService?.postMessageHandler(event);
    };

    private onWindowPreLoaderFinishedEvent = async (event) => {
        this._preLoaderManager.preloaderEventsHandler();
    };

    private onWindowClick = async (event: MouseEvent) => {
        this._clientTracking.onHandleClickEvents(event);

        const sessionManager = await this._sessionManagerPromise;
        sessionManager?.onUserInteraction();
    };

    private onWindowScroll = async () => {
        const sessionManager = await this._sessionManagerPromise;
        sessionManager?.onUserInteraction();
    };

    private onWindowResize = async () => {
        this._uiService.setDeviceTypeClass();
    };

    private onBurgerMenuClicked = async () => {
        this._userInteraction.burgerMenuClicked();
    };

    private onExitOffCanvasClicked = async () => {
        this._userInteraction.exitOffCanvasClicked();
    };
}
