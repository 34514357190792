import { Container, Service } from 'typedi';
import DeviceType from './enums/DeviceType';
import { ILogger, LoggerProvider } from '../logger';
import { COMPONENT_NAME } from '../../models/enums/Consts';
import { WindowToken } from '../../injection-tokens';
import {
    MessageBroker,
    IPlayerChannel,
    IUiChannel,
    ITopicSubscription,
    IDeviceTypeData,
    IDialogShowData,
    IToastData,
    ToastActionType,
    IFavouriteSportsData,
    IToastInputData,
} from '@sparkware/uc-sdk-core';
import { UserareaService } from '../external/userarea';
import { IToastOptions } from '@sparkware/uc-sdk-core/lib/apps/app-init-parameters.interface';

@Service()
export class UIService {
    private readonly _logger: ILogger;
    private readonly _window: Window;
    private readonly _channel: IUiChannel;
    private readonly _playerChannel: IPlayerChannel;
    private readonly _userareaService: UserareaService;

    private readonly PC_WIDTH = 1280;
    private _currentDevice: string = '';

    private _favouriteSportsSubscription: ITopicSubscription;

    constructor() {
        this._channel = MessageBroker.getInstance().ui;
        this._playerChannel = MessageBroker.getInstance().player;
        this._logger = Container.get(LoggerProvider).getLogger('UIService');
        this._window = Container.get(WindowToken);
        this._userareaService = Container.get(UserareaService);

        this.handleDialog = this.handleDialog.bind(this);
        this.handleFavouriteSports = this.handleFavouriteSports.bind(this);

        this.setDeviceTypeClass();
    }

    public setDeviceTypeClass = () => {
        const newDevice =
            this._window.innerWidth >= this.PC_WIDTH ? DeviceType.PC : DeviceType.Mobile;

        if (newDevice !== this._currentDevice) {
            const newDeviceClass = `ucf_${newDevice.toLowerCase()}`;
            const oldDeviceClass = `ucf_${this._currentDevice.toLowerCase()}`;
            this._logger.debug(
                `${newDevice} width detected, setting ucf_${newDevice.toLowerCase()} class`,
            );

            this._window.document.body.classList.add(newDeviceClass);
            this._window.document.body.classList.remove(oldDeviceClass);

            this._currentDevice = newDevice;

            this._channel.topics.DeviceUpdate.publish({ publisher: COMPONENT_NAME }, {
                Type: this._currentDevice,
            } as IDeviceTypeData);
        }
    };

    public handleDialog({ dialogType, options }: IDialogShowData): void {
        this._logger.log(`Publish DialogShow with type ${dialogType}`);

        this._userareaService.executeOnload(() => {
            this._channel.topics.DialogShow.publish(
                { publisher: 'UCF-UIService' },
                {
                    dialogType,
                    options,
                },
            );
        });
    }

    public handleFavouriteSports(data: IFavouriteSportsData): void {
        this._logger.log('Publish favouriteSports');

        //if userarea was not loaded when we received the message
        //re-publish when loaded to be catched by it
        const isLoadedUserarea = this._userareaService.findIfIsLoaded();
        if (!isLoadedUserarea) {
            this._userareaService.executeOnload(() => {
                this._playerChannel.topics.favouriteSports.publish(
                    { publisher: 'UCF-UIService' },
                    data,
                );
            });
        }

        //unsubscribe from here to not catch it again here
        this._favouriteSportsSubscription?.unsubscribe();
    }

    public showToast = (options: IToastOptions, data: IToastData | unknown): void => {
        const toastInput: IToastInputData = {
            actionType: ToastActionType.Show,
            options,
            data,
        };

        this._channel.topics.Toast.publish({ publisher: 'ui' }, toastInput);
    };

    public dismissToast = (id: string): void => {
        const toastOptions: IToastOptions = { toastId: id };

        const toastInput: IToastInputData = {
            actionType: ToastActionType.Dismiss,
            options: toastOptions,
        };

        this._channel.topics.Toast.publish({ publisher: 'ui' }, toastInput);
    };

    public destroyToasts = (): void => {
        const toastInput: IToastInputData = {
            actionType: ToastActionType.Destroy,
        };

        this._channel.topics.Toast.publish({ publisher: 'ui' }, toastInput);
    };
}
