import { ClickStreamEventInnerType, WebVitalsName } from '../enums/consts';
import Container, { Service } from 'typedi';
import {
    EventFormatterBuilder,
    EventFormatterBuilderFactory,
} from '@unified-client/event-formatter';

import { ClickStreamTrackingProvider } from '..';
import { ITrackingProvider } from './interfaces';
import IWebVitalsTrackingClickStreamEventData from './IWebVitalsTrackingClickStreamEventData';
import { Utils } from '../../utils';
import { Metric, MetricWithAttribution } from 'web-vitals/attribution';
import { UrlUtils } from '../../utils/urlUtils';

@Service()
export class WebVitalsElasticTracking implements ITrackingProvider {
    private _clickStreamTrackingProvider: ClickStreamTrackingProvider;
    private _eventFormatterBuilder: EventFormatterBuilder;
    private _utils: Utils;
    private _urlUtils: UrlUtils;

    constructor() {
        this._clickStreamTrackingProvider = Container.get(ClickStreamTrackingProvider);
        this._utils = Container.get(Utils);
        this._urlUtils = Container.get(UrlUtils);
        const eventFormatterBuilderFactory = Container.get(EventFormatterBuilderFactory);
        this._eventFormatterBuilder = eventFormatterBuilderFactory.createEventFormatterBuilder(
            'WebVitalsElasticTracking',
        );
    }

    track(eventData: Metric, correlationID: string, subComponentName: string): void {
        if (!eventData) return;

        if (!correlationID) correlationID = this._utils.getCorrelationId();
        const formatter = this._eventFormatterBuilder.createFormatter(subComponentName || 'track');

        let debugTarget = '';
        const eventDataWithAttribution = eventData as MetricWithAttribution;
        switch (eventData.name) {
            case 'CLS':
                debugTarget = eventDataWithAttribution?.attribution?.largestShiftTarget as string;
                break;
            case 'FID':
                debugTarget = eventDataWithAttribution?.attribution?.eventTarget as string;
                break;
            case 'LCP':
                debugTarget = eventDataWithAttribution?.attribution?.element as string;
                break;
            case 'INP':
                debugTarget = eventDataWithAttribution?.attribution?.eventTarget as string;
                break;
        }
        const elasticEventData: IWebVitalsTrackingClickStreamEventData = {
            event: eventData.name,
            delta: eventData.delta,
            value: eventData.value,
            id: eventData.id,
            innerType: ClickStreamEventInnerType.WebVitals,
            rating: eventData.rating,
            navigationType: eventData.navigationType,
            entries: JSON.stringify(eventData.entries),
            attribution: JSON.stringify((eventData as MetricWithAttribution)?.attribution),
            debugTarget,
            currentUrl: this._urlUtils.getCurrentUrl(),
        };

        const event = formatter.formatUCEvent(
            { message: elasticEventData.event, innerType: ClickStreamEventInnerType.WebVitals },
            { correlationID },
            elasticEventData,
        );

        this._clickStreamTrackingProvider.sendEventV2(event);
    }
}
