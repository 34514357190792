import { ICustomerRelationsChannel, ILogger, MessageBroker } from '@sparkware/uc-sdk-core';
import Container, { Service } from 'typedi';
import { LoggerProvider } from '../../logger';
import { CustomerRelationsService } from '../../customer-relations/customer.relations.service';
import { ICustomerRelationsRequest } from '../../customer-relations/models/ICustomerRelationsRequest';

@Service()
export class CustomerRelationChannelSubscriber {
    private readonly _logger: ILogger;
    private readonly _customerRelationsChannel: ICustomerRelationsChannel;
    private readonly _customerRelationsService: CustomerRelationsService;

    constructor() {
        this._logger = Container.get(LoggerProvider).getLogger('CustomerRelationChannelSubscriber');
        this._customerRelationsService = Container.get(CustomerRelationsService);
        this._customerRelationsChannel = MessageBroker.getInstance().customerRelations;
        this._customerRelationsChannel.topics.CTA_CustomerRelations_Open.subscribe(
            this.onCustomerRelationsOpen.bind(this),
        );
    }

    private onCustomerRelationsOpen = async (data: ICustomerRelationsRequest): Promise<void> => {
        this._customerRelationsService.openCustomerRelations(data);
    };
}
