import Container, { Service } from 'typedi';
import { ILogger, LoggerProvider } from '../../logger';
import { IAppClosedTopicPayload, ISessionChannel, MessageBroker } from '@sparkware/uc-sdk-core';
import { IMb2Cb } from '../../mb-2-cb/models/IMb2Cb';
import { PreloaderManager } from '../../ui/preloader/preloader-manager';
import { ISessionManager } from '../../session-manager/interfaces/ISessionManager';
import { LoaderManager } from '../../../loaders/LoaderManager';
import { IPushController } from '../../push/models/IPushController';

@Service()
export class SessionChannelSubscriber {
    private readonly _logger: ILogger;
    private readonly _sessionManagerChannel: ISessionChannel;
    private readonly _preLoaderManager: PreloaderManager;
    private _closeTimeout: string | number | NodeJS.Timeout;

    private get _mb2CBPromise(): Promise<IMb2Cb> {
        return LoaderManager.Instance.Mb2CbLoader.Instance;
    }

    private get _sessionManagerPromise(): Promise<ISessionManager> {
        return LoaderManager.Instance.SessionManagerLoader.Instance;
    }

    private get _pushPromise(): Promise<IPushController> {
        return LoaderManager.Instance.PushLoader.Instance;
    }

    constructor() {
        this._logger = Container.get(LoggerProvider).getLogger('SessionChannelSubscriber');
        this._preLoaderManager = Container.get(PreloaderManager);
        this._sessionManagerChannel = MessageBroker.getInstance().session;
        this._sessionManagerChannel.topics.appClosed.subscribe(this.onClose.bind(this));
        this._sessionManagerChannel.topics.clientInitSucceeded.subscribe(
            this.onClientInitSucceeded.bind(this),
        );
        this._sessionManagerChannel.topics.clientInitFailed.subscribe(
            this.onClientInitFailed.bind(this),
        );
        this._sessionManagerChannel.topics.inactivityTimeout.subscribe(
            this.onInactivityTimeout.bind(this),
        );
        this._sessionManagerChannel.topics.heartBeat.subscribe(this.onHeartBeat.bind(this));
    }

    private async onClose(data: IAppClosedTopicPayload) {
        this._logger.info(`[onClose]] start, data: ${JSON.stringify(data)}`);
        const mb2cb = await this._mb2CBPromise;
        if (mb2cb) {
            this._closeTimeout = setTimeout(async () => {
                await mb2cb.onClose(data);
                clearTimeout(this._closeTimeout);
            }, 0);
        }
    }

    private async onClientInitSucceeded(): Promise<void> {
        this._preLoaderManager.onClientInitSucceeded();
        const pushModule = await this._pushPromise;
        pushModule?.onClientInitSucceeded();
    }

    private async onClientInitFailed(data) {
        this._preLoaderManager.onClientInitFailed(data);
    }

    private async onInactivityTimeout() {
        const sessionManager = await this._sessionManagerPromise;
        sessionManager?.onInactivityTimeoutMessage();
    }

    private async onHeartBeat(data?: any) {
        const sessionManager = await this._sessionManagerPromise;
        sessionManager?.onHeartbeatMessage(data);
    }
}
