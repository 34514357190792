import Container from 'typedi';
import { WebPushLoader } from '../modules/web-push/web.push.loader';
import { CRMLoader } from '../modules/crm/crm.loader';
import { PushLoader } from '../modules/push/push.loader';
import { Mb2CbLoader } from '../modules/mb-2-cb/mb-2-cb.loader';
import { IdentityLoader } from '../modules/identity/identity.loader';
import { CrossSellLoader } from '../modules/cross-sell/cross-sell.loader';
import { OptimizelyLoader } from '../modules/optimizely/optimizely.loader';
import { PostLoginLoader } from '../modules/post-login/post-login.loader';
import { AppCatalogLoader } from '../modules/app-catalog/app-catalog.loader';
import { GeolocationLoader } from '../modules/geolocation/geolocation.loader';
import { AppLauncherLoader } from '../modules/app-launcher/app-launcher.loader';
import { MiniCashierLoader } from '../modules/mini-cashier/mini-cashier.loader';
import { NativeServiceLoader } from '../modules/native/native.service.loader';
import { ActionHandlerFactoryLoader } from '../modules/action-handler/action-handler.loader';
import { PendingHandlerLoader } from '../modules/pending/pending.handler.loader';
import { BIEventHandlerLoader } from '../modules/bi/bi-event-handler.loader';
import { MessagesHandlerLoader } from '../modules/push/messages.handler.loader';
import { CMSContentLoaderLoader } from '../modules/view-injector/utils/CMSContentLoader.loader';
import { BiometricsServiceLoader } from '../modules/biometrics/biometrics.service.loader';
import { UniqueTabServiceLoader } from '../modules/unique-tab/unique-tab.service.loader';
import { MrGreenMigrationLoader } from '../modules/mr-green-migration/mr-green-migration.loader';
import { RegistrationHandlerLoader } from '../modules/registration/registration.handler.loader';
import { AuthenticationStorageLoader } from '../modules/authentication/utils/authentication.storage.loader';
import { BiometricsControllerLoader } from '../modules/biometrics/biometrics.controller.loader';
import { RegistrationDataServiceLoader } from '../modules/registration/registration.data.service.loader';
import { AuthenticationFlowFactoryLoader } from '../modules/authentication/authentication-flow-factory.loader';
import { RegularAuthenticationServiceLoader } from '../modules/authentication/services/regular-authentication.service.loader';
import { CrossAuthenticationFlowRunnerLoader } from '../modules/authentication/flow-runners/cross-authentication-flow-runner.loader';
import { BiometricsAuthenticationServiceLoader } from '../modules/authentication/services/biometrics-authentication-service.loader';
import { RegularAuthenticationFlowRunnerLoader } from '../modules/authentication/flow-runners/regular-authentication-flow-runner.loader';
import { BiometricsAuthenticationFlowRunnerLoader } from '../modules/authentication/flow-runners/biometrics-authentication-flow-runner.loader';
import { SportLoader } from '../modules/sport/sport.loader';
import { PlayerLoader } from '../modules/player/player.loader';
import { SessionManagerLoader } from '../modules/session-manager/session-manager.loader';
import { LoyaltyIndicationLoader } from '../modules/loyalty-indication/loyalty-indication.loader';
import { PostMessageServiceLoader } from '../modules/post-message';
import { UkMigrationLoader } from '../modules/uk-migration/uk-migration.loader';

export class LoaderManager {
    private static _staticInstance: LoaderManager = null;

    private readonly _webPushLoader: WebPushLoader;
    private readonly _crmLoader: CRMLoader;
    private readonly _pushLoader: PushLoader;
    private readonly _mb2CbLoader: Mb2CbLoader;
    private readonly _identityLoader: IdentityLoader;
    private readonly _crossSellLoader: CrossSellLoader;
    private readonly _optimizelyLoader: OptimizelyLoader;
    private readonly _postLoginLoader: PostLoginLoader;
    private readonly _appCatalogLoader: AppCatalogLoader;
    private readonly _geolocationLoader: GeolocationLoader;
    private readonly _appLauncherLoader: AppLauncherLoader;
    private readonly _miniCashierLoader: MiniCashierLoader;
    private readonly _nativeServiceLoader: NativeServiceLoader;
    private readonly _actionHandlerFactoryLoader: ActionHandlerFactoryLoader;
    private readonly _pendingHandlerLoader: PendingHandlerLoader;
    private readonly _biEventHandlerLoader: BIEventHandlerLoader;
    private readonly _messagesHandlerLoader: MessagesHandlerLoader;
    private readonly _cmsContentLoaderLoader: CMSContentLoaderLoader;
    private readonly _biometricsServiceLoader: BiometricsServiceLoader;
    private readonly _uniqueTabServiceLoader: UniqueTabServiceLoader;
    private readonly _mrGreenMigrationLoader: MrGreenMigrationLoader;
    private readonly _registrationHandlerLoader: RegistrationHandlerLoader;
    private readonly _authenticationStorageLoader: AuthenticationStorageLoader;
    private readonly _biometricsControllerLoader: BiometricsControllerLoader;
    private readonly _registrationDataServiceLoader: RegistrationDataServiceLoader;
    private readonly _authenticationFlowFactoryLoader: AuthenticationFlowFactoryLoader;
    private readonly _regularAuthenticationServiceLoader: RegularAuthenticationServiceLoader;
    private readonly _crossAuthenticationFlowRunnerLoader: CrossAuthenticationFlowRunnerLoader;
    private readonly _biometricsAuthenticationServiceLoader: BiometricsAuthenticationServiceLoader;
    private readonly _regularAuthenticationFlowRunnerLoader: RegularAuthenticationFlowRunnerLoader;
    private readonly _biometricsAuthenticationFlowRunnerLoader: BiometricsAuthenticationFlowRunnerLoader;
    private readonly _sportLoader: SportLoader;
    private readonly _playerLoader: PlayerLoader;
    private readonly _sessionManagerLoader: SessionManagerLoader;
    private readonly _loyaltyIndicationLoader: LoyaltyIndicationLoader;
    private readonly _postMessageServiceLoader: PostMessageServiceLoader;
    private readonly _ukMigration: UkMigrationLoader;

    public static get Instance(): LoaderManager {
        if (!LoaderManager._staticInstance) {
            LoaderManager._staticInstance = new LoaderManager();
        }

        return LoaderManager._staticInstance;
    }

    constructor() {
        this._webPushLoader = Container.get(WebPushLoader);
        this._crmLoader = Container.get(CRMLoader);
        this._pushLoader = Container.get(PushLoader);
        this._mb2CbLoader = Container.get(Mb2CbLoader);
        this._identityLoader = Container.get(IdentityLoader);
        this._crossSellLoader = Container.get(CrossSellLoader);
        this._optimizelyLoader = Container.get(OptimizelyLoader);
        this._postLoginLoader = Container.get(PostLoginLoader);
        this._appCatalogLoader = Container.get(AppCatalogLoader);
        this._geolocationLoader = Container.get(GeolocationLoader);
        this._appLauncherLoader = Container.get(AppLauncherLoader);
        this._miniCashierLoader = Container.get(MiniCashierLoader);
        this._nativeServiceLoader = Container.get(NativeServiceLoader);
        this._actionHandlerFactoryLoader = Container.get(ActionHandlerFactoryLoader);
        this._pendingHandlerLoader = Container.get(PendingHandlerLoader);
        this._biEventHandlerLoader = Container.get(BIEventHandlerLoader);
        this._messagesHandlerLoader = Container.get(MessagesHandlerLoader);
        this._cmsContentLoaderLoader = Container.get(CMSContentLoaderLoader);
        this._biometricsServiceLoader = Container.get(BiometricsServiceLoader);
        this._uniqueTabServiceLoader = Container.get(UniqueTabServiceLoader);
        this._mrGreenMigrationLoader = Container.get(MrGreenMigrationLoader);
        this._registrationHandlerLoader = Container.get(RegistrationHandlerLoader);
        this._authenticationStorageLoader = Container.get(AuthenticationStorageLoader);
        this._biometricsControllerLoader = Container.get(BiometricsControllerLoader);
        this._registrationDataServiceLoader = Container.get(RegistrationDataServiceLoader);
        this._authenticationFlowFactoryLoader = Container.get(AuthenticationFlowFactoryLoader);
        this._regularAuthenticationServiceLoader = Container.get(
            RegularAuthenticationServiceLoader,
        );
        this._crossAuthenticationFlowRunnerLoader = Container.get(
            CrossAuthenticationFlowRunnerLoader,
        );
        this._biometricsAuthenticationServiceLoader = Container.get(
            BiometricsAuthenticationServiceLoader,
        );
        this._regularAuthenticationFlowRunnerLoader = Container.get(
            RegularAuthenticationFlowRunnerLoader,
        );
        this._biometricsAuthenticationFlowRunnerLoader = Container.get(
            BiometricsAuthenticationFlowRunnerLoader,
        );
        this._sportLoader = Container.get(SportLoader);
        this._playerLoader = Container.get(PlayerLoader);
        this._sessionManagerLoader = Container.get(SessionManagerLoader);
        this._loyaltyIndicationLoader = Container.get(LoyaltyIndicationLoader);
        this._postMessageServiceLoader = Container.get(PostMessageServiceLoader);
        this._ukMigration = Container.get(UkMigrationLoader);
    }

    public get WebPushLoader(): WebPushLoader {
        return this._webPushLoader;
    }

    public get CRMLoader(): CRMLoader {
        return this._crmLoader;
    }

    public get PushLoader(): PushLoader {
        return this._pushLoader;
    }

    public get Mb2CbLoader(): Mb2CbLoader {
        return this._mb2CbLoader;
    }

    public get IdentityLoader(): IdentityLoader {
        return this._identityLoader;
    }

    public get CrossSellLoader(): CrossSellLoader {
        return this._crossSellLoader;
    }

    public get OptimizelyLoader(): OptimizelyLoader {
        return this._optimizelyLoader;
    }

    public get PostLoginLoader(): PostLoginLoader {
        return this._postLoginLoader;
    }

    public get AppCatalogLoader(): AppCatalogLoader {
        return this._appCatalogLoader;
    }

    public get GeolocationLoader(): GeolocationLoader {
        return this._geolocationLoader;
    }

    public get AppLauncherLoader(): AppLauncherLoader {
        return this._appLauncherLoader;
    }

    public get MiniCashierLoader(): MiniCashierLoader {
        return this._miniCashierLoader;
    }

    public get NativeServiceLoader(): NativeServiceLoader {
        return this._nativeServiceLoader;
    }

    public get ActionHandlerFactoryLoader(): ActionHandlerFactoryLoader {
        return this._actionHandlerFactoryLoader;
    }

    public get PendingHandlerLoader(): PendingHandlerLoader {
        return this._pendingHandlerLoader;
    }

    public get BIEventHandlerLoader(): BIEventHandlerLoader {
        return this._biEventHandlerLoader;
    }

    public get MessagesHandlerLoader(): MessagesHandlerLoader {
        return this._messagesHandlerLoader;
    }

    public get CMSContentLoaderLoader(): CMSContentLoaderLoader {
        return this._cmsContentLoaderLoader;
    }

    public get BiometricsServiceLoader(): BiometricsServiceLoader {
        return this._biometricsServiceLoader;
    }

    public get UniqueTabServiceLoader(): UniqueTabServiceLoader {
        return this._uniqueTabServiceLoader;
    }

    public get MrGreenMigrationLoader(): MrGreenMigrationLoader {
        return this._mrGreenMigrationLoader;
    }

    public get RegistrationHandlerLoader(): RegistrationHandlerLoader {
        return this._registrationHandlerLoader;
    }

    public get AuthenticationStorageLoader(): AuthenticationStorageLoader {
        return this._authenticationStorageLoader;
    }

    public get BiometricsControllerLoader(): BiometricsControllerLoader {
        return this._biometricsControllerLoader;
    }

    public get RegistrationDataServiceLoader(): RegistrationDataServiceLoader {
        return this._registrationDataServiceLoader;
    }

    public get AuthenticationFlowFactoryLoader(): AuthenticationFlowFactoryLoader {
        return this._authenticationFlowFactoryLoader;
    }

    public get RegularAuthenticationServiceLoader(): RegularAuthenticationServiceLoader {
        return this._regularAuthenticationServiceLoader;
    }

    public get CrossAuthenticationFlowRunnerLoader(): CrossAuthenticationFlowRunnerLoader {
        return this._crossAuthenticationFlowRunnerLoader;
    }

    public get BiometricsAuthenticationServiceLoader(): BiometricsAuthenticationServiceLoader {
        return this._biometricsAuthenticationServiceLoader;
    }

    public get RegularAuthenticationFlowRunnerLoader(): RegularAuthenticationFlowRunnerLoader {
        return this._regularAuthenticationFlowRunnerLoader;
    }

    public get BiometricsAuthenticationFlowRunnerLoader(): BiometricsAuthenticationFlowRunnerLoader {
        return this._biometricsAuthenticationFlowRunnerLoader;
    }

    public get SportLoader(): SportLoader {
        return this._sportLoader;
    }

    public get PlayerLoader(): PlayerLoader {
        return this._playerLoader;
    }

    public get SessionManagerLoader(): SessionManagerLoader {
        return this._sessionManagerLoader;
    }

    public get LoyaltyIndicationLoader(): LoyaltyIndicationLoader {
        return this._loyaltyIndicationLoader;
    }

    public get PostMessageServiceLoader(): PostMessageServiceLoader {
        return this._postMessageServiceLoader;
    }

    public get UkMigrationLoader(): UkMigrationLoader {
        return this._ukMigration;
    }
}
