export enum AppLauncherElasticEvents {
    AppClicked = 'appClicked',
    AppInit = 'appInit',
    AppInitSucceeded = 'appInitSucceeded',
    AppInitFailed = 'appInitFailed',
    AppError = 'appError',
    AppLoadFailed = 'appLoadFailed',
    AppMinimized = 'appMinimized',
    AppMaximized = 'appMaximized',
    ShowMiniAppSuccess = 'showMiniAppSuccess',
    ShowMiniAppFailed = 'showMiniAppFailed',
}

export enum TriggerType {
    automaticFlow = 'automaticFlow',
    userSelection = 'userSelection',
}

export enum AreaType {
    email = 'email',
    button = 'button',
    banner = 'banner',
    externalClick = 'externalClick',
    tesetchannel = 'tesetchannel',
    UD = 'UD',
    Banner = 'Banner',
    ConfirmationScrean = 'ConfirmationScreen',
    Email = 'Email',
    HamburgerMenu = 'Hamburger menu',
    Header = 'Header',
    Message = 'Message',
    OMG = 'OMG',
    Overlay = 'Overlay',
    PCHeader = 'PC Header',
    PCLeftNavigationMenu = 'PC Left navigation menu',
    PendingAction = 'Pending action',
    Promotions = 'Promotions',
    SportBetslip = 'Sport Betslip',
    StaightToCashier = 'Straight to Cashier',
    UserArea = 'User area',
}

export enum ElementType {
    UD = 'UD',
    DepositButton = 'Deposit button',
    PlusButton = 'Plus button',
}

export enum SourceType {
    UD = 'UD',
    UnifiedClient = 'Unified Client',
    MyAccount = 'My account',
    Registration = 'Registration',
    OMG = 'OMG',
    Promotions = 'Promotions',
    KambiSportClient = 'Kambi Sport Client',
    LandingPage = 'Landing page',
    PendingAction = 'Pending action',
    Bnaner = 'Banner',
    Email = 'Email',
    DeepLink = 'Deeplink',
}

export enum CloseReason {
    userClosedApp = 'User closed app',
    automaticClosure = 'automatic closure',
    backendClosure = 'backend closure',
}

export enum AppWrapperErrorCodes {
    // GENERAL = 1,
    POPUP_BLOCKED = 2,
    RENDER_ERROR = 3,
}

export enum MBILEventsEnum {
    Close = 'Close',
    Focus = 'Focus',
    AppInitFailed = 'AppInitFailed',
    AppInitSucceeded = 'AppInitSucceeded',
    SetViewState = 'SetViewState',
    CloseIntent = 'CloseIntent',
    ProcessInAppMessage = 'ProcessInAppMessage',
    BankrollUpdate = 'BankrollUpdate',
    MinimizeApp = 'MinimizeApp',
}
