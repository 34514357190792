import Container from 'typedi';

import {
    ClientIntegrationFacadeToken,
    FeatureAvailabilityToken,
    UCFData,
} from '../../../injection-tokens';
import { ClientSettings } from './client-settings';
import { SpectateClientIntegrationFacade } from '../spectate-client-integration.facade';
import {
    IClientSettings,
    ISpectateClientSettings,
} from '@sparkware/uc-sdk-core/lib/apps/app-init-parameters.interface';
import { DeviceUtils } from '../../utils/deviceUtils';
import PageContextManager from 'page-context-manager';
import { Features, MarketingBrand, StaticPaths } from '../../../models/enums/Consts';
import { IFeatureAvailability } from '../../feature/feature-availability/feature-availability-interface';
import { LocalStoreService } from '../../storage/implementations/store';
import { StorageItemEnum } from '../../../models/enums/storage-enums';

export class SpectateClientSettings extends ClientSettings {
    private readonly _deviceUtils: DeviceUtils;
    private _featureAvailbaility: IFeatureAvailability;
    private readonly _localStoreService: LocalStoreService;

    constructor() {
        super();

        this._deviceUtils = Container.get(DeviceUtils);
        this._featureAvailbaility = Container.get(FeatureAvailabilityToken);
        this._localStoreService = Container.get(LocalStoreService);
    }

    public getClientSettings = (): ISpectateClientSettings => {
        const clientSettingsBase: IClientSettings = super.getClientSettings() as IClientSettings;

        const hasKambiBets = PageContextManager.getUserData().hasKambiBets;
        const marketingBrandId = PageContextManager.getBrandData().marketingBrandId;
        const ucfData = Container.get(UCFData);
        const isGeoComplyNDBSEnabled = this._featureAvailbaility.IsFeatureEnabled(
            Features.ENABLE_GC_NDBS,
        );
        const { isMobile } = PageContextManager.getDeviceData();
        const { geolocationEnabled } = PageContextManager.getRegulationData();
        const allowLogin = !(isMobile && geolocationEnabled && !isGeoComplyNDBSEnabled);

        return {
            ...clientSettingsBase,
            HasKambiBets: hasKambiBets,
            pageCorrelationId: ucfData.pageCorrelationId,
            marketingBrandID: marketingBrandId,
            nativeAppType: this._deviceUtils.getNativeAppType(),
            //This field determine if spectate permit login from sbetslip.
            // allowLogin = False , show prompt "Download app"
            // allowLogin = True , show LoginButton
            allowLogin,
        };
    };

    protected registerTokens() {
        super.registerTokens();
        Container.set(ClientIntegrationFacadeToken, new SpectateClientIntegrationFacade());
    }
}
