import { Container, Service } from 'typedi';
import { v4 as uuid } from 'uuid';
import { WindowToken } from '../../injection-tokens';
import { ClientProvider } from '../../models/enums/Consts';
import {
    EventFormatterBuilder,
    EventFormatterBuilderFactory,
} from '@unified-client/event-formatter';
import { ILogger, LoggerProvider } from '../logger';

import { ClickStreamTrackingProvider } from '../tracking';
import LogIndex from '../tracking/enums/LogIndex';
import { MigrationMonitoringData } from './models/IMigrationMonitoringData';
import { UrlUtils } from '../utils/urlUtils';
import { ISportMigrationData } from '@sparkware/uc-sdk-core/lib/apps/app-init-parameters.interface';
import PageContextManager from 'page-context-manager';

@Service()
export class GradualMigration {
    private readonly spectatePlayerNameValue = 'spectate_player';
    private readonly kambiPlayerNameValue = 'kambi_player';
    private readonly migrationCookieName = 'gradual_launch';
    private readonly yearInDays = 365;
    private readonly _logger: ILogger;
    private _clickStreamTrackingProvider: ClickStreamTrackingProvider;
    private _eventFormatterBuilder: EventFormatterBuilder;
    private _correlationID: string;
    private readonly _window: Window;
    private readonly _urlUtils: UrlUtils;
    private readonly batchNumberCookieName = 'batch_number';

    constructor() {
        this._logger = Container.get(LoggerProvider).getLogger('GradualMigration');
        const eventFormatterBuilderFactory = Container.get(EventFormatterBuilderFactory);
        this._eventFormatterBuilder =
            eventFormatterBuilderFactory.createEventFormatterBuilder('GradualMigration');
        this._clickStreamTrackingProvider = Container.get(ClickStreamTrackingProvider);
        this._correlationID = uuid();
        this._window = Container.get(WindowToken);
        this._urlUtils = Container.get(UrlUtils);
    }

    public processMigrationData = (data: ISportMigrationData) => {
        const { name } = PageContextManager.getClientProviderData();
        const sportMigrationData = data;
        const { spectateMigration } = PageContextManager.getMigrationData();
        const spectatePreMigrationEnabled = spectateMigration?.preMigrationEnabled;
        const spectateMigrationEnabled = spectateMigration?.enabled;

        if (name === ClientProvider.Kambi) {
            if (!sportMigrationData) {
                if (spectatePreMigrationEnabled) {
                    let cookieValue = '';
                    if (!!sportMigrationData?.BatchNumber) {
                        cookieValue = this.spectatePlayerNameValue;
                    } else {
                        cookieValue = this.kambiPlayerNameValue;
                    }

                    this.setGradualMigrationCookies(
                        name,
                        this.migrationCookieName,
                        cookieValue,
                        this.yearInDays,
                    );
                }
            }

            if ((spectatePreMigrationEnabled || spectateMigrationEnabled) && !!sportMigrationData) {
                if (spectateMigrationEnabled && sportMigrationData?.MigrationStatusCode === 2) {
                    // login req failed
                } else if (
                    !!sportMigrationData?.SpectateFirstLogin ||
                    !!sportMigrationData?.BatchNumber ||
                    (!sportMigrationData?.BatchNumber &&
                        !sportMigrationData?.SpectateFirstLogin &&
                        !sportMigrationData?.MigrationStatusCode)
                ) {
                    this.setGradualMigrationCookies(
                        name,
                        this.migrationCookieName,
                        this.spectatePlayerNameValue,
                        this.yearInDays,
                        sportMigrationData,
                    );
                } else {
                    this.setGradualMigrationCookies(
                        name,
                        this.migrationCookieName,
                        this.kambiPlayerNameValue,
                        this.yearInDays,
                        sportMigrationData,
                    );
                }
            }
        }

        if (name === ClientProvider.Spectate) {
            if (spectateMigrationEnabled && !!sportMigrationData) {
                if (sportMigrationData?.MigrationStatusCode === 2) {
                    // login req failed
                } else if (
                    !!sportMigrationData?.SpectateFirstLogin ||
                    !!sportMigrationData?.MigrationStartDate ||
                    !!sportMigrationData?.BatchNumber ||
                    (!sportMigrationData?.BatchNumber &&
                        !sportMigrationData?.SpectateFirstLogin &&
                        !sportMigrationData?.MigrationStatusCode)
                ) {
                    this.setGradualMigrationCookies(
                        name,
                        this.migrationCookieName,
                        this.spectatePlayerNameValue,
                        this.yearInDays,
                        sportMigrationData,
                    );
                } else {
                    // ClientProvider.Kambi;
                    this.setGradualMigrationCookies(
                        name,
                        this.migrationCookieName,
                        this.kambiPlayerNameValue,
                        this.yearInDays,
                        sportMigrationData,
                    );
                }
            }
        }
    };

    private setGradualMigrationCookies = (
        currentClientProvider: ClientProvider,
        migrationCookieName: string,
        value: string,
        days: number,
        sportMigrationData?,
    ) => {
        this._logger.debug(`Creating cookie: ${migrationCookieName} (${value})`);
        this.cookieCreatedElasticEvent(sportMigrationData, currentClientProvider + '', value);
        this.setCookie(migrationCookieName, value, days);
        !!sportMigrationData?.BatchNumber
            ? this.setCookie(this.batchNumberCookieName, sportMigrationData.BatchNumber, days)
            : () => {};
    };

    private setCookie = (name: string, value: string | number, days: number) => {
        let expires;
        if (days) {
            let date = new Date();
            date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
            expires = `; expires= ${date['toGMTString']()}`;
        } else {
            expires = '';
        }

        const cookieValue = `${name}=${value}; expires=${expires}; domain=${this._urlUtils.getDomain()}; path=/`;

        this._window.document.cookie = cookieValue;
        this._logger.debug(`Creating cookie: ${name} (${value})`);
    };

    private cookieCreatedElasticEvent = (
        sportMigrationData,
        currentClientProvider: string,
        newCookieValue: string,
    ) => {
        const migrationMonitoringData: MigrationMonitoringData = {
            event: 'cookie-created',
            sportMigrationData,
            playerType: newCookieValue,
            sessionID: PageContextManager.getSessionData()?.globalSessionId,
            currentClient: currentClientProvider,
        };
        const formatter = this._eventFormatterBuilder.createFormatter('cookie-created');
        const trackingData = formatter.formatUCEvent(
            { message: migrationMonitoringData.event },
            {
                correlationID: this._correlationID,
                targetOverride: LogIndex.SpectateGradualDeployment,
            },
            { ...migrationMonitoringData },
        );
        this._clickStreamTrackingProvider.sendEventV2(trackingData);
    };
}
