import Container, { Service } from 'typedi';
import { ILogger, LoggerProvider } from '../../logger';
import { IWalletChannel, MessageBroker } from '@sparkware/uc-sdk-core';
import { IBalanceUpdatedData } from '@sparkware/uc-sdk-core/lib/message-broker/channels/wallet/wallet.channel.interfaces';
import { IOptimizely } from '../../optimizely/interfaces';
import { LoaderManager } from '../../../loaders/LoaderManager';
import { Wallet } from '../../wallet';

@Service()
export class WalletChannelSubscriber {
    private readonly _logger: ILogger;
    private readonly _walletChannel: IWalletChannel;
    private readonly _wallet: Wallet;

    private get _optimizelyPromise(): Promise<IOptimizely> {
        return LoaderManager.Instance.OptimizelyLoader.Instance;
    }

    constructor() {
        this._logger = Container.get(LoggerProvider).getLogger('WalletChannelSubscriber');
        this._wallet = Container.get(Wallet);
        this._walletChannel = MessageBroker.getInstance().wallet;
        this._walletChannel.topics.BalanceUpdated.subscribe(this.onBalanceUpdated.bind(this));
        this._walletChannel.topics.UpdateBalance.subscribe(this.onUpdateBalance.bind(this));
    }

    private async onBalanceUpdated(data: IBalanceUpdatedData): Promise<void> {
        const optimizely = await this._optimizelyPromise;
        optimizely?.publishLowBalanceUpdate(data);
    }

    private async onUpdateBalance(): Promise<void> {
        this._wallet.onUpdateBalance();
    }
}
