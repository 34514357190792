export enum StorageItemEnum {
    ActionStarted = 'actionStarted',
    AuthorizationData = 'authorizationData',
    AuthorizationSessionId = 'authorizationSessionId',
    AutoLoginBackButton = 'autoLoginBackButton',
    AutoLoginData = 'autoLoginData',
    AutoLoginSourceProductPackage = 'autoLoginSourceProductPackage',
    AutologinRedirected = 'AutologinRedirected',
    BackButtonData = 'BackButtonData',
    BalancePollingRequired = 'BalancePollingRequired',
    BrandProductPackage = 'brandProductPackage',
    CID = 'CID',
    CashierOpenRequired = 'cashierOpenRequired',
    CashierOpenRequiredChannel = 'cashierOpenRequiredChannel',
    CashierOpenRequiredUploadDocument = 'cashierOpenRequiredUploadDocument',
    CashierOpenStartTimestamp = 'CashierOpenStartTimestamp',
    CashierPromoCode = 'cashierPromoCode',
    ClientThemeName = 'clientThemeName',
    DataStore = 'DataStore',
    ExpirationData = 'expirationData',
    FitToPlayFlow = 'FitToPlayFlow',
    GeolocationData = 'GeolocationData',
    InitGeolocationFlowRequired = 'InitGeolocationFlowRequired',
    IsBossMode = 'IsBossMode',
    IsCasinoAutoLogin = 'IsCasinoAutoLogin',
    IsPostLoginActivityHistoryProcessed = 'isPostLoginActivityHistoryProcessed',
    LoginNotification = 'loginNotification',
    MiniCashierDetails = 'MiniCashierDetails',
    MonitoringDataStore = 'MonitoringDataStore',
    NativeDataStore = 'NativeDataStore',
    NativeSDKSourceProductPackage = 'NativeSDKSourceProductPackage',
    NativeThemeMode = 'NativeThemeMode',
    NsdkFeatureAB = 'NsdkFeatureAB',
    OddsFormat = 'oddsFormat',
    PendingData = 'pendingData',
    PostLoginActionsInterrupted = 'postLoginActionsInterrupted',
    PostLoginActionsRegistrationAutoLogin = 'postLoginActionsRegistrationAutoLogin',
    PreventShowWinLossPupup = 'PreventShowWinLossPupup',
    RealityCheckTimeout = 'realityCheckTimeout',
    SiteSegmentationContext = 'siteSegmentationContext',
    ThemeMode = 'ThemeMode',
    TouchIDTimeout = 'TouchIDTimeout',
    UserIsLoggedIn = 'userIsLoggedIn',
    UserLoggedIn = 'userLoggedIn',
    UserName = 'userName',
    UserOnBackToForeground = 'userOnBackToForeground',
}
