import Container, { Service } from 'typedi';
import { IFullCashierOptions, IFullCashierPayment, IFullCashierPaymentCallbacks } from './models';

import { ActionID, IPerformActionTopicPayload } from '@sparkware/uc-sdk-core';
import { AppIdentifiers } from '@sparkware/uc-sdk-core';
import DataStoreHelper from '../../../Modules/Utils/DataStoreHelper';
import { IUnifiedCashierPayment } from './models';
import { MessageBroker } from '@sparkware/uc-sdk-core';
import StringUtils from '../../../Modules/Utils/StringUtils';
import { TriggerType } from '../app-launcher/enums';
import { v4 as uuidv4 } from 'uuid';
import {
    ClientsFrameworkOpenServiceProductService,
    CommonService,
} from '../external/clients-framework';
import { Utils } from '../utils';
import { StorageItemEnum } from '../../models/enums/storage-enums';
import { WalletService } from '../wallet';
import { SessionSimpleStoreService } from '../storage/implementations/simple-store';
import { IActionHandlerFactory } from '../action-handler/models/IActionHandlerFactory';
import { CookieStoreItemService } from '../storage/implementations';
import PageContextManager from 'page-context-manager';
import { LoaderManager } from '../../loaders/LoaderManager';

@Service()
export class PaymentsService {
    private readonly _cfOpenServiceProductService: ClientsFrameworkOpenServiceProductService;
    private readonly _utils: Utils;
    private readonly _sessionSimpleStoreService: SessionSimpleStoreService;
    private readonly _walletService: WalletService;
    private readonly _cookieService: CookieStoreItemService;
    private readonly _cfCommonService: CommonService;

    private get _actionHandlerFactoryPromise(): Promise<IActionHandlerFactory> {
        return LoaderManager.Instance.ActionHandlerFactoryLoader.Instance;
    }

    constructor() {
        this._cfOpenServiceProductService = Container.get(
            ClientsFrameworkOpenServiceProductService,
        );
        this._utils = Container.get(Utils);
        this._sessionSimpleStoreService = Container.get(SessionSimpleStoreService);
        this._walletService = Container.get(WalletService);
        this._cookieService = Container.get(CookieStoreItemService);
        this._cfCommonService = Container.get(CommonService);
    }

    async OpenCashier(
        fullCashierData: IFullCashierPayment = {},
        fullCashierCallbacks: IFullCashierPaymentCallbacks = {},
        options: IFullCashierOptions = {},
    ) {
        const { onClose } = fullCashierCallbacks;

        const { browser, isMobile } = PageContextManager.getDeviceData();

        const isSafari = browser.toLowerCase() === 'safari';
        const isNative = this._utils.findIfIsNative();

        if (!fullCashierData.PromoCode) {
            const sessionPromoCode = this._sessionSimpleStoreService.get(
                StorageItemEnum.CashierPromoCode,
            );
            if (sessionPromoCode) {
                fullCashierData.PromoCode = sessionPromoCode;
            }
        }

        this._sessionSimpleStoreService.remove(StorageItemEnum.CashierPromoCode);

        let clientAutoLoginData = {
            subBrandId: null,
            productPackageId: undefined,
            additionalActivationParameters: undefined,
            width: undefined,
            height: undefined,
            onClose: undefined,
            linkId: undefined,
            target: undefined,
            openInMobileContainer: undefined,
            showCloseButton: undefined,
            paramsToAdd: undefined,
            getUrl: undefined,
            functionToInvoke: undefined,
            classToAdd: undefined,
        };

        const cookieConsent = this._cookieService.get('OptanonConsent');

        if (isMobile || (!isMobile && isSafari && isNative)) {
            clientAutoLoginData.productPackageId = 69;

            // if (
            //     isMobile &&
            //     sessionStorage.postLoginRequired &&
            //     CommunicationHandler != undefined
            // ) {
            //     let currentAction =
            //         CommunicationHandler.getInstance().getCurrentAction();

            //     if (
            //         currentAction != undefined &&
            //         currentAction.Name === "UploadDocuments"
            //     )
            //         sessionStorage.setItem(
            //             "postLoginActionsInterrupted",
            //             "true"
            //         );
            // }

            if (isNative) {
                clientAutoLoginData.target = 'native';
                clientAutoLoginData.openInMobileContainer = false;
                clientAutoLoginData.showCloseButton = false;
                clientAutoLoginData.paramsToAdd = this.getNativeParamsForCashier();
            }

            if (!isNative)
                clientAutoLoginData.target = options.openCashierExternallyInMobileEnabled
                    ? '_blank'
                    : '_self';

            //for cashier new tab in safari from native app do we need callbackUrl?
            let callbackUrl = null;

            if (!isNative) {
                const { mainPage } = PageContextManager.getSiteData();

                callbackUrl =
                    sessionStorage.LastHash != undefined
                        ? location.origin + mainPage + sessionStorage.LastHash
                        : location.origin + mainPage;
            } else callbackUrl = PageContextManager.getNativeData()?.schemaUrl;

            let specificParams: any = {
                ...fullCashierData,
                SessionType:
                    !fullCashierData.TabId || fullCashierData.TabId === 8
                        ? 16
                        : fullCashierData.TabId,
                PromCode: fullCashierData.PromoCode || '',
                IsAutoLogin: 'False',
                OptanonConsent: cookieConsent && encodeURIComponent(cookieConsent),
                EnableBackButton: 'true',
                LobbyUrl: callbackUrl,
                ...(options?.openCashierThroughRedirect == true && {
                    IsCashierHostedInIframe: 'false',
                }),
            };

            clientAutoLoginData.additionalActivationParameters = this._createSpecificNavigation(
                'XML',
                specificParams,
            );
        } else if (!isMobile) {
            clientAutoLoginData.productPackageId = 11;

            const specificParams = {
                ...fullCashierData,
                SessionType: fullCashierData.TabId || 8,
                PromCode: fullCashierData.PromoCode || '',
                IsCashierHostedInIframe: 'false',
                OptanonConsent: cookieConsent && encodeURIComponent(cookieConsent),
            };

            clientAutoLoginData.additionalActivationParameters = this._createSpecificNavigation(
                'specificNavigation',
                specificParams,
            );

            if (isSafari) {
                clientAutoLoginData.width = null;
                clientAutoLoginData.height = null;
                clientAutoLoginData.target = '_blank';
            }

            if (!isSafari) {
                clientAutoLoginData.width = 1000;
                clientAutoLoginData.height = 665;
                clientAutoLoginData.onClose = !onClose
                    ? () => {
                          const isBalancePollingRequired = this._sessionSimpleStoreService.get(
                              StorageItemEnum.BalancePollingRequired,
                          );
                          if (isBalancePollingRequired === 'true')
                              this._walletService.sendCurrentUserBalanceUpdated();
                      }
                    : onClose;
            }
        }

        if (options?.openCashierThroughRedirect) {
            this.setCashierRedirectOpenProps(clientAutoLoginData);
        }

        const isOpenCashierExternal =
            clientAutoLoginData.target === '_blank' || clientAutoLoginData.target === 'native';
        if (isOpenCashierExternal && typeof onClose === 'function') onClose(null);

        return this._cfOpenServiceProductService.clientAutoLogin(
            clientAutoLoginData.subBrandId,
            clientAutoLoginData.productPackageId,
            clientAutoLoginData.additionalActivationParameters,
            clientAutoLoginData.width,
            clientAutoLoginData.height,
            clientAutoLoginData.onClose,
            clientAutoLoginData.linkId,
            clientAutoLoginData.target,
            clientAutoLoginData.openInMobileContainer,
            clientAutoLoginData.showCloseButton,
            clientAutoLoginData.paramsToAdd,
            clientAutoLoginData.getUrl,
            clientAutoLoginData.functionToInvoke,
        );
    }

    private setCashierRedirectOpenProps(cashierOpenData: any) {
        cashierOpenData.width = '';
        cashierOpenData.height = '';
        cashierOpenData.onClose = '';
        cashierOpenData.target = '_self';
        cashierOpenData.openInMobileContainer = false;
        cashierOpenData.showCloseButton = true;
    }

    async CloseCashier(): Promise<void> {
        return this._cfCommonService.CloseWrapDiv();
    }

    public openCashierQuickDeposit() {
        const data: IFullCashierPayment = { TabId: 16 };
        this.OpenCashier(data);
    }

    public openCashierWithdraw() {
        const data: IFullCashierPayment = { TabId: 4 };
        this.OpenCashier(data);
    }

    private getNativeParamsForCashier(): Array<any> {
        let params: Array<any> = [];

        const testData = DataStoreHelper.getNativeStoreItem('TestData');

        if (!StringUtils.isNullOrWhiteSpaces(testData)) {
            params.push({
                key: 'TestData',
                value: '' + testData + '',
            });
        }

        const sr = DataStoreHelper.getNativeStoreItem('SR');
        if (!StringUtils.isNullOrWhiteSpaces(sr)) {
            params.push({
                key: 'Serial',
                value: '' + sr + '',
            });
        }

        const afudid = DataStoreHelper.getNativeStoreItem('AFUDID');
        if (!StringUtils.isNullOrWhiteSpaces(afudid)) {
            params.push({
                key: 'AppsFlyerID',
                value: '' + afudid + '',
            });
        }

        const appleId = DataStoreHelper.getNativeStoreItem('APPLEID');
        if (!StringUtils.isNullOrWhiteSpaces(appleId)) {
            // for iOS
            params.push({
                key: 'AppID',
                value: 'id' + appleId + '',
            });
        } else {
            const appId = DataStoreHelper.getNativeStoreItem('AppID');
            if (!StringUtils.isNullOrWhiteSpaces(appId)) {
                // for android
                params.push({
                    key: 'AppID',
                    value: '' + appId + '',
                });
            }
        }

        const idfa = DataStoreHelper.getNativeStoreItem('IDFA');
        if (!StringUtils.isNullOrWhiteSpaces(idfa)) {
            params.push({
                key: 'DeviceID',
                value: '' + idfa + '',
            });
        } else {
            const deviceId = DataStoreHelper.getNativeStoreItem('DeviceID');

            if (!StringUtils.isNullOrWhiteSpaces(deviceId)) {
                params.push({
                    key: 'DeviceID',
                    value: '' + deviceId + '',
                });
            }
        }
        return params;
    }

    async OpenWidget(unifiedCashierPayment: IUnifiedCashierPayment) {
        const navigationChannel = MessageBroker.getInstance().navigation;
        const { clientVersion } = PageContextManager.getDeviceData();

        const data: IPerformActionTopicPayload<any, any> = {
            actionID: unifiedCashierPayment?.ActionId || ActionID.deposit,
            correlationID: uuidv4(),
            launchInfo: {
                businessCorrelationID: uuidv4(),
                sequentialCorrelationID: 1,
                channel: unifiedCashierPayment.Channel,
                appSpecificParameters: {},
                trigger: TriggerType.userSelection,
                containerID: undefined,
                sourceAppID: AppIdentifiers.UnifiedClient,
                sourceAppVersion: '0.0.0', // TODO change to correct version
                clientVersion: clientVersion,
            },
            actionData: {
                AnalyticsData: {
                    action: unifiedCashierPayment.AnalyticsData.action,
                    category: unifiedCashierPayment.AnalyticsData.category,
                    label: unifiedCashierPayment.AnalyticsData.label,
                },
            },
        };

        if (unifiedCashierPayment?.PromoCode)
            Object.assign(data.launchInfo.appSpecificParameters, {
                promoCode: unifiedCashierPayment.PromoCode,
            });

        if (unifiedCashierPayment?.TabId)
            Object.assign(data.launchInfo.appSpecificParameters, {
                tabId: unifiedCashierPayment.TabId,
            });

        if (unifiedCashierPayment?.OnClose)
            Object.assign(data.launchInfo.appSpecificParameters, {
                onClose: unifiedCashierPayment.OnClose,
            });

        const actionHandlerFactory = await this._actionHandlerFactoryPromise;
        await actionHandlerFactory.performAction(data);
    }

    private _createSpecificNavigation = (tagName, specificParams) => {
        const specificNavigation = Object.keys(specificParams)
            .filter(this._emptyParameterFilter(specificParams))
            .reduce(this._createParamsReducer(specificParams), '');
        return encodeURIComponent(`<${tagName}>${specificNavigation}</${tagName}>`);
    };

    private _emptyParameterFilter = (specificParams) => (key) =>
        typeof specificParams[key] !== 'undefined' &&
        specificParams[key] !== null &&
        specificParams[key] !== '';

    private _createParamsReducer = (specificParams) => (acc, key) =>
        acc + `<${key}>${specificParams[key]}</${key}>`;
}
