import { ClientThemeName } from '@sparkware/uc-sdk-core/lib/apps/app-enums';

export const COMPONENT_NAME: string = 'unifiedclient';

export enum PlatformMessagesType {
    Omg = 1,
    Toaster = 2,
    Kick = 3,
    RaceFinished = 4,
    RaceBoosterNotification = 5,
    UpdateRestriction = 6,
    Logout = 7,
    SegmentationUpdate = 8,
    InitGeo = 9,
    UpdateRestrictionV2 = 10,
    PromotionEligibilityUpdate = 14,
    NightTimeRestrictionNotice = 16,
}

export enum KickType {
    Hard = 1,
    Soft = 2,
}

export enum PlatformLogoutReason {
    ClientLeave = 0, //clientLeave
    ClientKicked = 1,
    SocketClose = 2,
    LostPing = 3,
    SendLogoutMsg = 4,
    LoginError = 5,
    GameTimeout = 6,
    ClientLeaveAck = 7,
    ClientReconnected = 8,
    MainCrashed = 9,
    MainIsGoingDown = 10,
    ClientDisconnected = 11,
    RemoveEntryFromAMDB = 12,
    KickUserAlreadyLoggedIn = 20,
    KickedToLobbyDueToMobileLocation = 21,
    KickedToLobbyDueToRegulatorRequest = 22,
    ClientInactivity = 23,
    RDPDetected = 24,
    OutsideTheCountry = 25,
    TechnicalProblem = 26,
    PlugInNotInstalled = 27,
    PlugInNotUpdated = 28,
    LocationProcessTimedOut = 29,
    TimeLimitReached = 30,
    DistanceThresholdCheckFailed = 31,
    OptInError = 32,
    NetworkSwitch = 33,
    GCTimeout = 34,
    MobileLocationServicesDisabled = 35,
    KnownProxy = 36,
    LoginExpired = 37,
    KickDueToGamstop = 38,
    WeeklyTimeLimitReached = 39,
    MonthlyTimeLimitReached = 40,
    PlayerRestrictedFromPlay = 50,
    PendingActionRequired = 51,
}

export enum PreloaderComponentType {
    Client = 'Client',
    ClientContent = 'ClientContent',
    SportWrapper = 'SportWrapper',
    RedirectHash = 'RedirectHash',
    CrossAutoLogin = 'CrossAutoLogin',
}

export enum PreloaderElasticEvent {
    ClientFinished = 'ClientOnLoadReady',
    ClientFailed = 'ClientOnLoadFailed',
    ClientContentSucceeded = 'OfferingContentReady',
    ClientContentFailed = 'OfferingContentFailed',
    UnifiedClientContentReady = 'UnifiedClientContentReady',
    ClientOnContentFailed = 'ClientOnContentLoadFailed',
    ClientOnClientFailed = 'ClientOnClientLoadFailed',
}

export enum Config {
    SessionTimerInterval = 60 * 1000,
}

export enum CloseSessionReason {
    ClientLeave = 0,
    ClientKicked = 1,
    SocketClose = 2,
    LostPing = 3,
    SendLogoutMsg = 4,
    LoginError = 5,
    GameTimeout = 6,
    ClientLeaveAck = 7,
    ClientReconnected = 8,
    MainCrashed = 9,
    MainIsGoingDown = 10,
    ClientDisconnected = 11,
    RemoveEntryFromAMDB = 12,
    KickUserAlreadyLoggedIn = 20,
    KickedToLobbyDueToMobileLocation = 21,
    KickedToLobbyDueToRegulatorRequest = 22,
    ClientInactivity = 23,
    RDPDetected = 24,
    OutsideTheCountry = 25,
    TechnicalProblem = 26,
    PlugInNotInstalled = 27,
    PlugInNotUpdated = 28,
    LocationProcessTimedOut = 29,
    TimeLimitReached = 30,
    DistanceThresholdCheckFailed = 31,
    OptInError = 32,
    NetworkSwitch = 33,
    GCTimeout = 34,
    MobileLocationServicesDisabled = 35,
    KnownProxy = 36,
    LoginExpired = 37,
    KickDueToGamstop = 38,
    WeeklyTimeLimitReached = 39,
    MonthlyTimeLimitReached = 40,
}

export enum ViewSource {
    FirstHit = 'FirstHit',
    Sport = 'Sport',
    Content = 'Content',
}

export enum IdsOfElements {
    HiddenContent = 'hidden-content',
    Main = 'main',
    SpectateSportClientView = 'SpectateSportClientView',
    UcMainViewAppContainer = 'uc-main-view-app-container',
    UcContainer = 'uc-container',
    UcContentContainer = 'uc-content-container',
    UcContentArea = 'uc-content-area',
    UcCMSContainer = 'uc-cms-container',
    UcFooter = 'uc-footer',
    UcFullContent = 'uc-full-content',
    UcSpectateSportClientView = 'uc-spectate-sport-client-view',
    UcWrapperContainer = 'uc-wrapper-container',
    CookieMessage = 'CookieMessageDiv',
    CasinoContent = 'page',
}

export enum ClassesOfElements {
    UcContentArea = 'uc-content-area',
}

export enum FlowComponents {
    ViewInjector = 'ViewInjector',
}

export enum StaticPaths {
    Root = '/',
    PokerRoot = '/play-poker-online/',
    ErrorPage = '/error404.aspx',
}

export enum Deeplinks {
    OpenLogin = 'openlogin',
    OpenNrs = 'opennrs',
    OpenNrsar = 'opennrsar',
    OpenCasino = 'opencasino',
    OpenLiveCasino = 'openlivecasino',
    OpenVegas = 'openvegas',
    OpenPoker = 'openpoker',
    OpenCashier = 'opencashier',
    OpenMyChallenges = 'mychallenges',
    OpenRich = 'openrich',
    LoginToCashier = 'logintocashier',
    OpenControlCenter = 'opencc',
}

export enum ApplicationCatalog {
    Pending = 2,
}

export enum RedirectPageHash {
    FreeBets = 'showFreeBets',
}

export enum ClientProvider {
    Kambi = 'Kambi',
    Spectate = 'Spectate',
    Poker = 'Poker',
}

export enum LoginType {
    Manual = 'Manual',
    Auto = 'Auto',
}

export const Brand = {
    Sport_Com: 8,
    Sport_DK: 75,
    Sport_ES: 58,
    Sport_IT: 31,
    Sport_RO: 76,
    Sport_Scasino: 0,
    Sport_SE: 81,
    Sport_SH: 83,
    Sport_Spoker: 1,
    Sport_DE: 84,
    Sport_US_CO: 86,
    Sport_CA_ON: 87,
    Sport_NL: 88,
    Sport_US_VA: 89,
    Sport_US_IN: 90,
    Sport_US_MI: 91,
    Sport_MG_SE: 92,
    Sport_MG_DK: 94,
    Sport_MG_MGA: 95,
    Sport_UK: 96,
};

export const GeoLocationBrands: number[] = [
    Brand.Sport_CA_ON,
    Brand.Sport_US_CO,
    Brand.Sport_US_VA,
    Brand.Sport_US_IN,
    Brand.Sport_US_MI,
];

export const SmartBannerNotRequiredFtdBrands: number[] = [Brand.Sport_CA_ON];

export const CountryCode = {
    GBR: 'gbr',
    USA: 'usa',
};

export enum PageType {
    Client = 'Client',
    Content = 'Content',
}

export const SPINNER_PROMISE_TIMEOUT = 1000;
export const RESIZE_DEFAULT_TIMEOUT_BASE = 1000;

export const GTMDataLayerName = 'dataLayer';

export const CookieType = {
    UserSelectedState: 'userSelectedState',
};

export enum StateTagGroup {
    State = 'state',
}

export enum StateType {
    Vanilla = 'vanilla',
}

export enum StatusCodes {
    ACCEPTED = 202,
    BAD_GATEWAY = 502,
    BAD_REQUEST = 400,
    CONFLICT = 409,
    CONTINUE = 100,
    CREATED = 201,
    EXPECTATION_FAILED = 417,
    FAILED_DEPENDENCY = 424,
    FORBIDDEN = 403,
    GATEWAY_TIMEOUT = 504,
    GONE = 410,
    HTTP_VERSION_NOT_SUPPORTED = 505,
    IM_A_TEAPOT = 418,
    INSUFFICIENT_SPACE_ON_RESOURCE = 419,
    INSUFFICIENT_STORAGE = 507,
    INTERNAL_SERVER_ERROR = 500,
    LENGTH_REQUIRED = 411,
    LOCKED = 423,
    METHOD_FAILURE = 420,
    METHOD_NOT_ALLOWED = 405,
    MOVED_PERMANENTLY = 301,
    MOVED_TEMPORARILY = 302,
    MULTI_STATUS = 207,
    MULTIPLE_CHOICES = 300,
    NETWORK_AUTHENTICATION_REQUIRED = 511,
    NO_CONTENT = 204,
    NON_AUTHORITATIVE_INFORMATION = 203,
    NOT_ACCEPTABLE = 406,
    NOT_FOUND = 404,
    NOT_IMPLEMENTED = 501,
    NOT_MODIFIED = 304,
    OK = 200,
    PARTIAL_CONTENT = 206,
    PAYMENT_REQUIRED = 402,
    PERMANENT_REDIRECT = 308,
    PRECONDITION_FAILED = 412,
    PRECONDITION_REQUIRED = 428,
    PROCESSING = 102,
    PROXY_AUTHENTICATION_REQUIRED = 407,
    REQUEST_HEADER_FIELDS_TOO_LARGE = 431,
    REQUEST_TIMEOUT = 408,
    REQUEST_TOO_LONG = 413,
    REQUEST_URI_TOO_LONG = 414,
    REQUESTED_RANGE_NOT_SATISFIABLE = 416,
    RESET_CONTENT = 205,
    SEE_OTHER = 303,
    SERVICE_UNAVAILABLE = 503,
    SWITCHING_PROTOCOLS = 101,
    TEMPORARY_REDIRECT = 307,
    TOO_MANY_REQUESTS = 429,
    UNAUTHORIZED = 401,
    UNAVAILABLE_FOR_LEGAL_REASONS = 451,
    UNPROCESSABLE_ENTITY = 422,
    UNSUPPORTED_MEDIA_TYPE = 415,
    USE_PROXY = 305,
}

export const GetTokenErrorCodes = {
    NoRecordsFound: 100,
    NightLimitReached: 210,
};

export enum ActionHandlerErrorCode {
    TechnicalProblem = 11,
}

export const ActionHandlerErrorCodeDescription = {
    [ActionHandlerErrorCode.TechnicalProblem]: 'technical problem',
};

export enum ApiErrorCodes {
    Unknown = 0,
    MissingUserData = 1,
}

export enum LoadingSpeed {
    SUPER_FAST = 0,
    FAST = 1,
    MEDIUM = 2,
    SLOW = 3,
}

export const NAVIGATION_IGNORE_ATTRIBUTE = 'data-navigation-ignore';
export const IMAGE_LINK_REGEX = /\.(svg|jpg|jpeg|png|bmp|webp|gif)$/i;

export const GENERIC_LOGIN_ERROR_CODE = 1;

export enum TokenType {
    Regular = 1,
    Biometrics = 2,
    Migration = 8,
}

export enum MembershipActions {
    Autologin = '1',
    Deposit = '2',
    Back = '3',
    CloseWindow = '6',
    UploadDocument = '7',
    BankId = '64',
    StoreRegistrationCookiesData = '69',
    DownloadNativeSportApp = '68',
    ShowWebLogin = '73',
    ShowKYC = '75',
    RedirectClient = '77',
}

export enum NativeUCAppType {
    Sport888 = 'Sport888',
}

export const PERFORM_ACTION_INTERVAL = 1 * 1000;

export enum NavigateLinkId {
    ToSportOffers = 1,
    ToTouchId = 20,
}

export const FIT_TO_PLAY_BRANDS = [Brand.Sport_CA_ON];

export enum PendingActionEnum {
    IdentityVerification = 25,
    GamingCautionSWE = 40,
}

export enum PendingEventTypeEnum {
    Login = 8,
    Play = 21,
    OpenApp = 63,
}

export enum PendingPlayModeEnum {
    All = 0,
    Demo = 1,
    Real = 2,
}

export enum PendingCriteriaTemplateEnum {
    AllSubBrandsAndPackages = 1,
    CashierPackageOnly = 2,
    NTSPackageAndMyAccountPackage = 3,
    LoginToNTSOnly = 4,
    PcOnly = 8,
    CasinoAndPoker = 12,
    DepositAndTrasactionHistoryWidget = 21,
    TransactionHistoryWidget = 22,
    DepositWidget = 23,
}

export enum UnifiedTheme {
    Default = 'default',
    SportsIllustrated = 'SportsIllustrated',
    Poker = 'Poker',
    MrGreen = 'MrGreen',
}

export const WidgetTheme = {
    [UnifiedTheme.Default]: ClientThemeName.Sport888,
    [UnifiedTheme.MrGreen]: ClientThemeName.MrGreen,
    [UnifiedTheme.Poker]: ClientThemeName.Poker888,
    [UnifiedTheme.SportsIllustrated]: ClientThemeName.SI,
};

export enum MarketingBrand {
    Triple8 = 1,
    SportsIllustrated = 2,
    MrGreen = 3,
    WilliamHill = 4,
}

export enum TestName {
    B2CABTest = 'B2CABTest',
}

export enum Features {
    IOS_NATIVE_CASINO = 'IOS_NATIVE_CASINO',
    THEME_MODE = 'THEME_MODE',
    LOYALTY_INDICATION = 'LOYALTY_INDICATION',
    AIRSHIP = 'AIRSHIP',
    SMART_BANNER = 'SMART_BANNER',
    GERMANY_REGULATION = 'GERMANY_REGULATION',
    INIT_GEO_RETRY = 'INIT_GEO_RETRY',
    FAVOURITE_SPORTS = 'FAVOURITE_SPORTS',
    NEW_BANNER = 'NEW_BANNER',
    SURVEY = 'SURVEY',
    TOUCH_ID = 'TOUCH_ID',
    MINI_CASHIER = 'MINI_CASHIER',
    SINGLE_TAB_LOGGED_IN = 'SINGLE_TAB_LOGGED_IN',
    APP_UPGRADE_REQUIRED = 'APP_UPGRADE_REQUIRED',
    ENABLE_GC_NDBS = 'ENABLE_GC_NDBS',
}

export enum SDKType {
    Web = 1,
    NativeSDK = 2,
    AppWrapper = 3,
}

export const PENDING_INIT_TIMEOUT = 20 * 1000;

export enum ToastType {
    DefaultUC = 'DefaultUC',
    RegulationLogin = 'RegulationLogin',
}

export enum RegulationType {
    All = -1,
    Denmark = 8,
}

export enum PendingClientOperations {
    Ready = '65',
    QuickDeposit = '7',
    Cashier = '6',
    ShowVM = '75',
    ControlCentre = '101',
    Pending = 'Pending',
}
