import Container, { Service } from 'typedi';
import { ILogger, LoggerProvider } from '../../logger';
import {
    IBiometricsChannel,
    IBiometricsEnrollmentFinishedRequestData,
    IBiometricsEnrollmentRequestData,
    IBiometricsEnrollmentStatusRequestData,
    IBiometricsLoginCancelledRequestData,
    IBiometricsLoginIntentRequestData,
    IBiometricsLoginRequestData,
    IBiometricsSettingsRequestData,
    IBiometricsStatusData,
    ITokenRequestData,
    MessageBroker,
} from '@sparkware/uc-sdk-core';
import { IBiometricsController } from '../../biometrics/models/IBiometricsController';
import { LoaderManager } from '../../../loaders/LoaderManager';

@Service()
export class BiometricsChannelSubscriber {
    private readonly _logger: ILogger;
    private readonly _biometricsChannel: IBiometricsChannel;

    private get _biometricsControllerPromise(): Promise<IBiometricsController> {
        return LoaderManager.Instance.BiometricsControllerLoader.Instance;
    }

    constructor() {
        this._logger = Container.get(LoggerProvider).getLogger('BiometricsChannelSubscriber');
        this._biometricsChannel = MessageBroker.getInstance().biometrics;

        this._biometricsChannel.topics.biometricsStatus.subscribe(
            this._onBiometricsStatus.bind(this),
        );

        this._biometricsChannel.topics.enrollment.subscribe(this._onEnrollment.bind(this));

        this._biometricsChannel.topics.enrollmentFinished.subscribe(
            this._onEnrollmentFinished.bind(this),
        );

        this._biometricsChannel.topics.enrollmentStatus.subscribe(
            this._onEnrollmentStatus.bind(this),
        );

        this._biometricsChannel.topics.requestToken.subscribe(
            this._onEnrollmentRequestToken.bind(this),
        );

        this._biometricsChannel.topics.biometricSettings.subscribe(
            this._onBiometricSettings.bind(this),
        );

        this._biometricsChannel.topics.login.subscribe(this._onBiometricLogin.bind(this));

        this._biometricsChannel.topics.loginIntent.subscribe(
            this._onBiometricLoginIntent.bind(this),
        );

        this._biometricsChannel.topics.loginCancelled.subscribe(
            this._onBiometricLoginCancelled.bind(this),
        );
    }

    private async _onBiometricsStatus(status: IBiometricsStatusData) {
        const biometricsController = await this._biometricsControllerPromise;
        if (biometricsController) {
            await biometricsController.onBiometricsStatus(status);
        }
    }

    private async _onEnrollment(enrollmentRequest: IBiometricsEnrollmentRequestData) {
        const biometricsController = await this._biometricsControllerPromise;
        if (biometricsController) {
            await biometricsController.onEnrollment(enrollmentRequest);
        }
    }

    private async _onEnrollmentFinished(
        enrollmentFinishedRequest: IBiometricsEnrollmentFinishedRequestData,
    ) {
        const biometricsController = await this._biometricsControllerPromise;
        if (biometricsController) {
            await biometricsController.onEnrollmentFinished(enrollmentFinishedRequest);
        }
    }

    private async _onEnrollmentStatus(enrollmentStatus: IBiometricsEnrollmentStatusRequestData) {
        const biometricsController = await this._biometricsControllerPromise;
        if (biometricsController) {
            await biometricsController.onEnrollmentStatus(enrollmentStatus);
        }
    }

    private async _onEnrollmentRequestToken(enrollmentTokenRequest: ITokenRequestData) {
        const biometricsController = await this._biometricsControllerPromise;
        if (biometricsController) {
            await biometricsController.onEnrollmentRequestToken(enrollmentTokenRequest);
        }
    }
    private async _onBiometricSettings(settingsRequest: IBiometricsSettingsRequestData) {
        const biometricsController = await this._biometricsControllerPromise;
        if (biometricsController) {
            await biometricsController.onBiometricSettings(settingsRequest);
        }
    }

    private async _onBiometricLogin(loginRequest: IBiometricsLoginRequestData) {
        const biometricsController = await this._biometricsControllerPromise;
        if (biometricsController) {
            await biometricsController.onBiometricLogin(loginRequest);
        }
    }

    private async _onBiometricLoginIntent(loginIntentRequest: IBiometricsLoginIntentRequestData) {
        const biometricsController = await this._biometricsControllerPromise;
        if (biometricsController) {
            await biometricsController.onBiometricLoginIntent(loginIntentRequest);
        }
    }
    private async _onBiometricLoginCancelled(
        loginCancelledRequest: IBiometricsLoginCancelledRequestData,
    ) {
        const biometricsController = await this._biometricsControllerPromise;
        if (biometricsController) {
            await biometricsController.onBiometricLoginCancelled(loginCancelledRequest);
        }
    }
}
