import Container, { Service } from 'typedi';
import {
    EventFormatterBuilder,
    EventFormatterBuilderFactory,
    Level,
} from '@unified-client/event-formatter';
import { ILogger, LoggerProvider } from '../../logger';
import {
    PageType,
    PreloaderComponentType,
    PreloaderElasticEvent,
} from '../../../models/enums/Consts';
import {
    PerformanceElasticEvents,
    PerformanceMarks,
    PerformanceMeasures,
} from '../../performance/enums/consts';
import { SessionStorageToken, WindowToken } from '../../../injection-tokens';
import { ClickStreamTrackingProvider } from '../../tracking';
import { IPreloaderComponentClickStreamEventData } from './models/interfaces/IPreloaderComponentClickStreamEventData';
import { IPreloaderComponentResponseData } from './models/interfaces/IPreloaderComponentResponseData';
import { PerformanceManager } from '../../performance/performance-manager';
import { PreloaderWrapper } from './preloader-wrapper';
import { Router } from '../../router';
import { Utils } from '../../utils';
import { UserareaService } from '../../external/userarea';
import StringUtils from '../../../../Modules/Utils/StringUtils';
import { UrlUtils } from '../../utils/urlUtils';
import { RouterUtils } from '../../router/router.utils';

@Service()
export class PreloaderManager {
    private readonly _logger: ILogger;
    private readonly _trackingProvider: ClickStreamTrackingProvider;
    private readonly _preloaderWrapper: PreloaderWrapper;
    private readonly _window: Window;
    private readonly _routerUtils: RouterUtils;
    private readonly _preloaderStorage: Storage;
    private readonly _performanceManager: PerformanceManager;
    private readonly _utils: Utils;
    private readonly _eventFormatterBuilder: EventFormatterBuilder;
    private readonly _userareaService: UserareaService;
    private readonly _router: Router;
    private readonly _urlUtils: UrlUtils;
    private readonly _storageKey: string = 'PreloaderManager';
    private readonly _isSportClient: boolean;
    private _sportWrapperLoaded: boolean = false;
    private _runClientInitSubscriptions: boolean = true;

    constructor() {
        this._logger = Container.get(LoggerProvider).getLogger('PreloaderManager');
        this._trackingProvider = Container.get(ClickStreamTrackingProvider);
        this._preloaderWrapper = Container.get(PreloaderWrapper);
        this._routerUtils = Container.get(RouterUtils);
        this._window = Container.get(WindowToken);
        this._performanceManager = Container.get(PerformanceManager);
        this._preloaderStorage = Container.get(SessionStorageToken);
        this._urlUtils = Container.get(UrlUtils);
        this._utils = Container.get(Utils);
        this._router = Container.get(Router);
        const eventFormatterBuilderFactory = Container.get(EventFormatterBuilderFactory);
        this._eventFormatterBuilder =
            eventFormatterBuilderFactory.createEventFormatterBuilder('PreloaderManager');
        this._userareaService = Container.get(UserareaService);
        this._isSportClient = this._routerUtils.isSportClientEnabled();
        this.init();
    }

    private init() {
        this._setPreloaderWatchEvents();
        this._registerUserareaReadySubscriber();
    }

    private _registerUserareaReadySubscriber = () => {
        this._userareaService.executeOnload(() => {
            this._onUserareaReady({ isLoaded: true });
        });
    };

    public preloaderEventsHandler = () => {
        const isClientOnContentFailed = this._isClientOnContentFailed();
        const isClientFailed = this._isClientFailedToLoadServer();

        //if on client and first failed on content and it is not failed from backend
        //and sport wrapper was loaded
        if (
            this._isSportClient &&
            isClientOnContentFailed &&
            !isClientFailed &&
            !!this._sportWrapperLoaded
        )
            this._onClientFailedOnContentFailed();
    };

    public onClientInitSucceeded = () => {
        if (this._isSportClient && this._runClientInitSubscriptions) {
            this.onComponentFinished(
                PreloaderComponentType.ClientContent,
                { isLoaded: true },
                PreloaderElasticEvent.ClientContentSucceeded,
            );

            this._performanceManager.sendReport(
                PerformanceMeasures.ClientInit,
                PerformanceMarks.GetClientSettingsStart,
                PerformanceElasticEvents.ClientInitSucceeded,
                true,
            );

            this._runClientInitSubscriptions = false;
        }
    };

    public onClientInitFailed = (data) => {
        if (this._isSportClient && this._runClientInitSubscriptions) {
            this._performanceManager.sendFailedReport(
                PerformanceMeasures.ClientInit,
                PerformanceMarks.GetClientSettingsStart,
                PerformanceElasticEvents.ClientInitFailed,
                data.error.errorCode,
                data.error.errorDescription,
                true,
            );

            const preloaderComponentResponseData: IPreloaderComponentResponseData = {
                isLoaded: false,
                errorCode: data.error.errorCode,
                errorDescription: StringUtils.toString(data.error.errorDescription),
            };

            this.onComponentFinished(
                PreloaderComponentType.ClientContent,
                preloaderComponentResponseData,
                PreloaderElasticEvent.ClientContentFailed,
            );

            this._runClientInitSubscriptions = false;
        }
    };

    private _onUserareaReady = (data) => {
        this.onComponentFinished(
            PreloaderComponentType.SportWrapper,
            { isLoaded: data.isLoaded },
            PreloaderElasticEvent.UnifiedClientContentReady,
        );

        this._sportWrapperLoaded = data.isLoaded;

        //if wrapper loaded
        if (data.isLoaded) {
            const isSportClient = this._routerUtils.isSportClientEnabled();
            const isClientFailed = this._isClientFailedToLoadServer();
            const isClientOnContentFailed = this._isClientOnContentFailed();

            //if is sport client and client failed to load from backend and client failed first on content
            if (isSportClient && isClientFailed && isClientOnContentFailed) {
                this._preloaderWrapper.finish(false);
                this._onClientFailedOnContentFailed();
            }

            if (!isSportClient) {
                if (!isClientFailed && isClientOnContentFailed)
                    this._storeClientOnContentResult(false);

                if (isClientFailed) {
                    this._router.Disable();

                    if (!isClientOnContentFailed) this._storeClientOnContentResult(true);

                    this._trackEvent(null, PreloaderElasticEvent.ClientOnContentFailed, false);
                }
            }
        } else this._storeClientOnContentResult(false);
    };

    //method to execute when client is failed (dataprovider null or prel. timeout or failed from spectate)
    private _onClientFailedOnContentFailed(): void {
        this._preloaderWrapper.remove();
        this._preloaderWrapper.showFailed();

        this._trackEvent(null, PreloaderElasticEvent.ClientOnClientFailed, false);

        this._storeClientOnContentResult(false);

        this._router.Disable();
    }

    private _isClientOnContentFailed(): boolean {
        const preloaderManagerStorage = this._preloaderStorage.getItem(this._storageKey);

        if (!!preloaderManagerStorage) {
            const preloaderManagerStorageItem = JSON.parse(preloaderManagerStorage);
            return !!preloaderManagerStorageItem.IsClientOnContentFailed;
        }

        return false;
    }

    private _storeClientOnContentResult(isFailed: boolean): void {
        const preloaderManagerStorage = this._preloaderStorage.getItem(this._storageKey);

        let newStorageItem;
        if (!!preloaderManagerStorage) {
            const oldStorageItem = JSON.parse(preloaderManagerStorage);
            newStorageItem = Object.assign({}, oldStorageItem);

            this._preloaderStorage.removeItem(this._storageKey);
        }

        newStorageItem = Object.assign({}, { IsClientOnContentFailed: isFailed });
        this._preloaderStorage.setItem(this._storageKey, JSON.stringify(newStorageItem));
    }

    public onComponentFinished(
        component: PreloaderComponentType,
        responseData: IPreloaderComponentResponseData,
        elasticEvent?: PreloaderElasticEvent,
    ): boolean {
        if (!!elasticEvent) this._trackEvent(responseData, elasticEvent);

        if (!responseData.isLoaded) this._logger.error(`${component} event failed to load`);
        else this._logger.log(`${component} event succeeded to load`);

        this._preloaderWrapper.onEventFinished(component, responseData);

        return true;
    }

    private _trackEvent(
        responseData: IPreloaderComponentResponseData,
        elasticEvent?: PreloaderElasticEvent,
        appendDuration: boolean = true,
    ): void {
        const isClientNavigation = this._routerUtils.isClientNavigation(this._window.location.href);
        let isError = false;

        let trackEventData: IPreloaderComponentClickStreamEventData = {
            event: elasticEvent,
            pageType: isClientNavigation ? PageType.Client : PageType.Content,
            path: decodeURI(this._window.location.pathname),
            host: this._window.location.host,
        };

        if (appendDuration) trackEventData.duration = this._preloaderWrapper.getDuration();

        if (responseData && !responseData.isLoaded) {
            trackEventData.errorCode =
                !responseData.errorCode || isNaN(Number(responseData.errorCode))
                    ? 0
                    : Number(responseData.errorCode);
            trackEventData.errorCodeString = StringUtils.toString(responseData.errorCode);
            trackEventData.errorDescription = StringUtils.toString(responseData.errorDescription);
            isError = true;
        }

        const correlationID = this._utils.getCorrelationId();
        const formatter = this._eventFormatterBuilder.createFormatter('_trackEvent');
        const event = formatter.formatUCEvent(
            {
                message: elasticEvent,
                ...(appendDuration && {
                    durationInMS: trackEventData.duration,
                }),
            },
            { correlationID, ...(isError && { level: Level.error }) },
            trackEventData,
        );

        this._trackingProvider.sendEventV2(event);
    }

    private _setPreloaderWatchEvents(): void {
        const isSportClient = this._routerUtils.isSportClientEnabled();
        const preloaderWatchEvents: Array<string> = [];

        if (isSportClient) {
            preloaderWatchEvents.push(
                PreloaderComponentType.Client,
                PreloaderComponentType.ClientContent,
            );

            const linkId = this._urlUtils.getLinkId();
            const isClientMode = this._urlUtils.isClientMode();

            if (!isClientMode) preloaderWatchEvents.push(PreloaderComponentType.SportWrapper);

            if (linkId) preloaderWatchEvents.push(PreloaderComponentType.RedirectHash);
        } else preloaderWatchEvents.push(PreloaderComponentType.SportWrapper);

        const isAutoLoginRequest = this._urlUtils.isAutologinRequest();

        if (!!isAutoLoginRequest) {
            preloaderWatchEvents.push(PreloaderComponentType.CrossAutoLogin);
        }

        this._logger.log(
            `Preloader initialising with '${preloaderWatchEvents.toString()}' events to listen for`,
        );

        this._preloaderWrapper.init(preloaderWatchEvents);
    }

    private _isClientFailedToLoadServer(): boolean {
        return !this._window['sportClientDataProvider'];
    }
}
