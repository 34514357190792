import Container, { Service } from 'typedi';
import { IAuthenticationResult, IAutoLoginResponseModel } from '../authentication/models';
import { ILogger, LoggerProvider } from '../logger';
import {
    IOpenTouchidSettingsPageData,
    IPlayerChannel,
    MessageBroker,
} from '@sparkware/uc-sdk-core';
import { BiometricsUtils } from '../biometrics/biometrics.utils';
import { IBiometricsLogin } from '../biometrics/models';
import { UiChannel } from '@sparkware/uc-sdk-core/lib/message-broker/channels/ui/ui.channel';
import StringHelper from '../../../Modules/Utils/StringUtils';
import { CryptoUtils } from '../utils/crypto.utils';
import { ClientsFrameworkInitService } from '../external/clients-framework';
import { FeatureAvailabilityToken, UserContextToken } from '../../injection-tokens';
import { IUserContext } from '../user-context/user-context-interface';
import {
    LocalSimpleStoreService,
    SessionSimpleStoreService,
} from '../storage/implementations/simple-store';
import { IBiometricsAuthenticationService } from '../authentication/models';
import PageContextManager from 'page-context-manager';
import DataStoreHelper from '../../../Modules/Utils/DataStoreHelper';
import { Features } from '../../models/enums/Consts';
import { IFeatureAvailability } from '../feature/feature-availability/feature-availability-interface';
import { INativeService } from './models/INativeService';
import { IBiometricsService } from '../biometrics/models/IBiometricsService';
import { LoaderManager } from '../../loaders/LoaderManager';

@Service()
export class TouchIdService {
    private readonly _playerChannel: IPlayerChannel;
    protected readonly _cfInitService: ClientsFrameworkInitService;
    private readonly _logger: ILogger;
    private readonly _biometricsUtils: BiometricsUtils;
    private readonly _uiChannel: UiChannel;
    private readonly _localSimpleStoreService: LocalSimpleStoreService;
    private readonly _sessionSimpleStoreService: SessionSimpleStoreService;
    private readonly _userContext: IUserContext;
    private readonly _featureAvailability: IFeatureAvailability;

    private _isAuthenticated: boolean = false;
    private _isExistingToken: boolean = false;
    private _hashedCid: string = null;

    private get _nativeServicePromise(): Promise<INativeService> {
        return LoaderManager.Instance.NativeServiceLoader.Instance;
    }

    private get _biometricsServicePromise(): Promise<IBiometricsService> {
        return LoaderManager.Instance.BiometricsServiceLoader.Instance;
    }

    private get _biometricsAuthenticationServicePromise(): Promise<IBiometricsAuthenticationService> {
        return LoaderManager.Instance.BiometricsAuthenticationServiceLoader.Instance;
    }

    constructor() {
        this._logger = Container.get(LoggerProvider).getLogger('TouchIdService');

        this._sessionSimpleStoreService = Container.get(SessionSimpleStoreService);
        this._localSimpleStoreService = Container.get(LocalSimpleStoreService);
        this._playerChannel = MessageBroker.getInstance().player;
        this._cfInitService = Container.get(ClientsFrameworkInitService);
        this._userContext = Container.get(UserContextToken);
        this._logger = Container.get(LoggerProvider).getLogger('TouchIdService');
        this._biometricsUtils = Container.get(BiometricsUtils);
        this._uiChannel = MessageBroker.getInstance().ui;
        this._featureAvailability = Container.get(FeatureAvailabilityToken);

        this._logger.log('Initialise');

        this.isAuthenticated = this.isAuthenticated.bind(this);
        this.onIosInitCompleted = this.onIosInitCompleted.bind(this);
        this.resetTouchId = this.resetTouchId.bind(this);
        this.saveTouchIdToken = this.saveTouchIdToken.bind(this);
        this.saveSuggestedStatus = this.saveSuggestedStatus.bind(this);
        this.showTouchIdPopup = this.showTouchIdPopup.bind(this);
        this.touchIdPostLoginFlow = this.touchIdPostLoginFlow.bind(this);
        this.suggestionFlow = this.suggestionFlow.bind(this);
        this.continueSuggestionFlow = this.continueSuggestionFlow.bind(this);
        this.checkIfSameUser = this.checkIfSameUser.bind(this);
        this.continueSameUserFlow = this.continueSameUserFlow.bind(this);
        this.doLoginFlow = this.doLoginFlow.bind(this);
        //this.sendLogin = this.sendLogin.bind(this);
    }

    public async getAndSaveToken(callback: Function = undefined) {
        const onSuccess = async (data) => {
            nativeService.saveItem('TouchIdToken', data.Token);
            nativeService.saveItem('RandomNumber', data.RandomNumber);
            nativeService.saveItem('Cid', data.HashedCid);
            if (typeof callback === 'function') callback();
        };

        const nativeService = await this._nativeServicePromise;
        if (nativeService) {
            const { response } = await nativeService.getAuthorizeData();

            if (response) onSuccess(response);
        }
    }

    public async isAuthenticated(callback: Function = undefined) {
        this._isAuthenticated = this._userContext.IsAuthenticated;

        if (callback != undefined) callback();
    }

    public async onIosInitCompleted(event) {
        if (StringHelper.isJsonString(event.data)) {
            const message = JSON.parse(event.data);
            if (message.command == 'onShowTouchIdCompleted') {
                //if touch id success
                if (message.content == 'true') {
                    this._hashedCid = message.Cid;
                    this._isExistingToken = true;

                    const biometricsLoginData: IBiometricsLogin = {
                        hashedCID: this._hashedCid,
                        token: message.Token,
                        randomNumber: message.RandomNumber,
                    };
                    const biometricsAuthenticationService =
                        await this._biometricsAuthenticationServicePromise;
                    const result: IAuthenticationResult<IAutoLoginResponseModel> =
                        await biometricsAuthenticationService.autoLogin(biometricsLoginData);

                    const { response, errorResponse } = result;
                    const isLoggedIn: boolean =
                        response.Response?.PendingToken || response.Response?.BearerToken
                            ? true
                            : false;
                    const biometricsService = await this._biometricsServicePromise;
                    biometricsService.AutoLoginCallback({ isLoggedIn });
                } else if (message.content == 'EnterPassword')
                    this._playerChannel.topics.loginCompletion.publish(
                        {
                            publisher: 'TouchIdService',
                        },
                        null,
                    );
            } else if (message.command == 'onIosInitCompleted') {
                // check if there's a token stored in local storage and only then showTouchId - relevant for page start when user already enrolled
                const isTouchIdEnabled = this._featureAvailability.IsFeatureEnabled(
                    Features.TOUCH_ID,
                );
                if (!!isTouchIdEnabled == true) this.isAuthenticated(this.showTouchIdPopup);
            } else if (message.command == 'onGetCidCompleted') {
                this._hashedCid = message.Cid;
                this.checkIfSameUser(this.continueSameUserFlow);
            }
        }
    }

    public async resetTouchId() {
        const nativeService = await this._nativeServicePromise;
        if (nativeService) {
            nativeService.resetTouchId();
        }

        this._localSimpleStoreService.remove('TouchIdSuggested');
        this._localSimpleStoreService.remove('TouchIdTurnedOn');

        this._isExistingToken = false;
    }

    public async saveTouchIdToken(callback?: Function) {
        await this.getAndSaveToken(callback);
    }

    public saveSuggestedStatus() {
        const cid = this._sessionSimpleStoreService.get('CID');

        if (cid) {
            const hashedCID = CryptoUtils.SHA256Hash(cid);
            this._localSimpleStoreService.set('TouchIdSuggested', hashedCID);

            this._logger.debug(
                `saveSuggestedStatus: Saving CID: ${cid} as a hash: ${hashedCID} ...`,
            );
        }
    }

    private showTouchIdPopup() {
        const touchIdTurnedOn = this._localSimpleStoreService.get('TouchIdTurnedOn');
        const isTouchId = DataStoreHelper.getNativeStoreItem('IsTouchIdCanBeUsed');

        if (
            isTouchId != undefined &&
            isTouchId == 'true' &&
            touchIdTurnedOn != undefined &&
            touchIdTurnedOn == 'true' &&
            this._sessionSimpleStoreService.get('IsDisplayedTouchId') !== 'true' &&
            !this._isAuthenticated
        ) {
            let textToDisplay = PageContextManager.getTouchIdData().loginText;
            if (textToDisplay == null) textToDisplay = 'Login to 888Sport';

            this._sessionSimpleStoreService.set('IsDisplayedTouchId', 'true');

            this._biometricsUtils.IsFitToPlay().then(async (isFitToPlay) => {
                if (isFitToPlay) {
                    const nativeService = await this._nativeServicePromise;
                    if (nativeService) {
                        nativeService.showTouchId(textToDisplay);
                    }
                } else {
                    this._logger.log('showTouchIdPopup: The player is not fit to play.');
                }
            });
        }
    }

    public async touchIdPostLoginFlow() {
        // check if touch id supported for sport
        const touchIdTurnedOn = this._localSimpleStoreService.get('TouchIdTurnedOn');
        if (touchIdTurnedOn != undefined && touchIdTurnedOn == 'true') {
            // check if same user
            const nativeService = await this._nativeServicePromise;
            if (nativeService) {
                nativeService.getCid();
            }
        }
        // check if touch id was already suggested to user
        else this.suggestionFlow();
    }

    public suggestionFlow() {
        const cid = this._sessionSimpleStoreService.get('CID');
        let hashedCID = undefined;

        if (cid) {
            hashedCID = CryptoUtils.SHA256Hash(cid);
        }

        this._logger.debug(
            `suggestionFlow: Continue suggestion flow for CID: ${
                cid || '-'
            } as a hash: ${hashedCID} ...`,
        );

        this.continueSuggestionFlow(hashedCID);
    }

    public continueSuggestionFlow(cidForSuggestion) {
        const touchIdSuggested = this._localSimpleStoreService.get('TouchIdSuggested');
        if (touchIdSuggested == undefined || touchIdSuggested != cidForSuggestion) {
            // suggeset touch id
            const targetUrl = PageContextManager.getSiteData().mainPage;

            this._uiChannel.topics.OpenTouchidSettingsPage.publish(
                {
                    publisher: 'UCF.RouterService',
                },
                {
                    enrollmentMode: true,
                    callbackUrl: targetUrl,
                } as IOpenTouchidSettingsPageData,
            );
        }

        // already suggested, continue login flow
        else this.doLoginFlow();
    }

    public checkIfSameUser(callback) {
        const cid = this._sessionSimpleStoreService.get('CID');
        const hashedCID = CryptoUtils.SHA256Hash(cid);
        const isSameUser = hashedCID === this._hashedCid;
        this._logger.debug(
            `checkIfSameUser: CID: ${cid} | HASHEDCID: ${hashedCID} | NATIVECID: ${this._hashedCid} | IS_SAME_USER: ${isSameUser}`,
        );

        callback(isSameUser);
    }

    public continueSameUserFlow(isSameUser: boolean) {
        if (isSameUser) {
            this._logger.debug(`continueSameUserFlow: doing login flow...`);

            if (this._isExistingToken) this.doLoginFlow();
            else this.getAndSaveToken(this.doLoginFlow);
        } else {
            this._logger.debug(
                `continueSameUserFlow: resetting touchId and going through suggestion flow...`,
            );

            this.resetTouchId();
            this.suggestionFlow();
        }
    }

    public async doLoginFlow() {
        const cid = this._sessionSimpleStoreService.get('CID');
        const biometricsService = await this._biometricsServicePromise;
        await biometricsService.enrollmentFinished({ cid });
        // let finalURL = DataStoreHelper.getStoreItem("MainPage");
        // if (this._sessionStorage.LastHash != undefined)
        //     finalURL += this._sessionStorage.LastHash;
        // const url = this._window.location.pathname + this._window.location.hash;
        // if (url == finalURL) this._window.location.reload();
        // else this._window.location.href = finalURL;
    }
}
